import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/Buttons/Button";
import SelectLanguage from "components/SelectLanguage";

import { ELimitations, usePlanLimitation } from "hooks/usePlanLimitation";

import { userPlanInfoSelector } from "store/user/selectors";
import { ETariffPlan } from "store/services/types";

import { getCurrentPlan } from "utils/plan";

import { userSaveLocalSettingsRequest } from "../../../../../store/user/reducers";

const SidebarBottom: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: userPlanInfo } = useSelector(userPlanInfoSelector);
  const { openUpgradePlan } = usePlanLimitation({
    limitType: ELimitations.directToPlan,
  });

  const showUpgradePlan = userPlanInfo?.current_plan.tariff_plan !== ETariffPlan.pro;

  const handleChangeLanguage = (lng: string) => {
    dispatch(userSaveLocalSettingsRequest({ data: { language: lng } }));
  };

  return (
    <div className="sidebar-bottom">
      <div className="sidebar-bottom__current-plan">
        {t("upgradePlan.currentPlan")}: <span>{getCurrentPlan(userPlanInfo?.current_plan.tariff_plan)}</span>
      </div>
      {showUpgradePlan && <div className="sidebar-bottom__upgrade-plan">
        <Button
          title={t("upgradePlan.upgradeNow")}
          pink={true}
          onClick={openUpgradePlan}
        />
      </div>}
      <SelectLanguage
        clasName="sidebar-bottom__lang"
        placement="topCenter"
        renderInWrapper={true}
        onChangeLng={handleChangeLanguage}
      />
    </div>
  );
};

export default SidebarBottom;
