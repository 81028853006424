import { FC, RefObject, useRef } from 'react';
import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { QUERIES } from "config/constants";

import useOnClickOutside from "hooks/useOnClickOutside";
import useQuery from "hooks/useQuery";

import { ETariffPlan, TServicesData } from "store/services/types";

import { analyticEvent } from "../../../../../../store/analytics/effects";
import { EEventType } from "../../../../../../store/analytics/types";

import PriceActivateMainInfoBody from "../PriceActivateMainInfoBody";

export type TPriceActivateMainInfoOverlayProps = {
  wrapRef: RefObject<HTMLDivElement>
  setVisible: (val: boolean) => void
  variants?: TServicesData[]
}
const PriceActivateMainInfoOverlay: FC<TPriceActivateMainInfoOverlayProps> = ({
  wrapRef, variants, setVisible
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clickRef = useRef<HTMLDivElement>(null);
  const query = useQuery();
  const tariff = query.get(QUERIES.tariff) as ETariffPlan;

  useOnClickOutside({
    anchors: [wrapRef, clickRef],
    onClickOutside: () => setVisible(false)
  });

  const handleClick = (service: TServicesData) => {
    dispatch(analyticEvent(EEventType.PAYMENT_STEP1_ALT_SELECTED));
    dispatch(analyticEvent(EEventType.PAYMENT_STEP1_SERVICE_CODE.replace("{service_code}", service.code)));
    query.set(QUERIES.code, service.code);
    history.replace({
      search: query.toString()
    });
    setVisible(false);
  };

  return (
    <div ref={clickRef}>
      <Menu className="pricing__activate__main-info__overlay">
        {!!variants?.length && variants.map(item => (
          <PriceActivateMainInfoBody
            key={item.id}
            tariff={tariff}
            price={item.price}
            currency={item.currency}
            code={item.code}
            monthlyPayment={item.monthly_payment}
            menuElement={true}
            onClick={() => handleClick(item)}
          />
        ))}
      </Menu>
    </div>
  );
};

export default PriceActivateMainInfoOverlay;
