import { FC } from "react";

import { IIconsProps } from "config/types";

{/* eslint-disable max-len */}
const FluentShieldOk: FC<IIconsProps> = ({ className, onClick }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <g clipPath="url(#clip0_9202_107849)">
      <path d="M7.71167 0.127465C7.78924 0.0456675 7.89254 0 8 0C8.10746 0 8.21076 0.0456675 8.28833 0.127465C9.20767 1.09658 10.5163 1.62238 11.634 1.90215C12.187 2.04002 12.6803 2.11482 13.0347 2.15515C13.218 2.17584 13.4019 2.19075 13.586 2.19989H13.5933C13.7021 2.20276 13.8055 2.25231 13.8815 2.33796C13.9575 2.4236 14 2.53855 14 2.65823V6.23326C14 9.71553 12.3133 13.2059 8.12433 14.6458C8.04338 14.6736 7.95662 14.6736 7.87567 14.6458C5.79633 13.9308 4.31967 12.7054 3.36567 11.1925C2.41467 9.6829 2 7.91518 2 6.1416V2.65823C1.99997 2.53855 2.04249 2.4236 2.11848 2.33796C2.19447 2.25231 2.2979 2.20276 2.40667 2.19989H2.414L2.44067 2.19879C2.61589 2.18935 2.79084 2.17468 2.96533 2.15479C3.43639 2.10122 3.90411 2.01674 4.366 1.90178C5.48333 1.62238 6.792 1.09658 7.71167 0.127098V0.127465ZM10.871 5.55639C10.9117 5.51515 10.9446 5.4654 10.9676 5.41004C10.9907 5.35468 11.0035 5.29482 11.0053 5.23396C11.007 5.17311 10.9978 5.11247 10.978 5.05559C10.9583 4.99871 10.9284 4.94673 10.8902 4.90268C10.852 4.85864 10.8061 4.82342 10.7554 4.79907C10.7047 4.77472 10.65 4.76174 10.5947 4.76088C10.5393 4.76002 10.4844 4.7713 10.4331 4.79406C10.3817 4.81682 10.335 4.85061 10.2957 4.89345L6.92333 8.43439L5.71133 7.10081C5.63318 7.01484 5.52719 6.96654 5.41667 6.96654C5.30615 6.96654 5.20015 7.01484 5.122 7.10081C5.04385 7.18677 4.99995 7.30337 4.99995 7.42494C4.99995 7.54652 5.04385 7.66311 5.122 7.74908L6.622 9.3991C6.782 9.5751 7.04067 9.57876 7.20433 9.40643L10.871 5.55639Z" fill="#59BD83"/>
    </g>
    <defs>
      <clipPath id="clip0_9202_107849">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default FluentShieldOk;
