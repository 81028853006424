import React, { FC, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import cn from "classnames";

import { maxMobileMediaWidth } from "config/constants";

import List from "components/List";
import BNSpinner from "components/BNSpinner";
import Button from "components/Buttons/Button";

import { getMyAssetsSelector } from "store/assets/selectors";
import { TMyAssetsData } from "store/assets/types";

import AMLPageWrapperWalletsItem from "../AMLPageWrapperWalletsItem";

const MAX_AML_LIST_HEIGHT = 302;

const AMLPageWrapperWallets: FC<{ onClickAddWallet: () => void, assets: TMyAssetsData[] }> = ({
  onClickAddWallet, assets
}) => {
  const { t } = useTranslation();
  const { fetching } = useSelector(getMyAssetsSelector);
  const isMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const getList = () => {
    if (!!assets?.length) {
      return (
        // eslint-disable-next-line no-console
        <div ref={wrapperRef}>
          {assets?.map(item => (
            <AMLPageWrapperWalletsItem
              key={item.id}
              id={item.id}
              name={item.name}
              source={item?.blockchain ? item?.blockchain : ""}
              risk={item?.risk_score ? item?.risk_score : 0}
              transactionsCount={item.new_transactions}
              icon={item.icon}
            />
          ))}
        </div>
      );
    }
    return <h3 className="empty-text">{t("assetsList.emptyText")}</h3>;
  };

  const scrollable =
    (wrapperRef.current?.offsetHeight && wrapperRef.current?.offsetHeight > MAX_AML_LIST_HEIGHT)
    || assets?.length > 4;

  const borderTop = (() => {
    if (!containerRef.current) {
      return false;
    }
    return scrollable && scrollPosition > 10;
  })();

  const borderBottom = (() => {
    if (!containerRef.current) {
      return false;
    }
    return scrollable && scrollPosition + containerRef.current.offsetHeight + 10 < containerRef.current.scrollHeight;
  })();

  const handleScroll = (e: unknown) => {
    const $e = e as React.BaseSyntheticEvent<HTMLDivElement>;
    if ($e.target?.scrollTop) {
      const position = $e.target.scrollTop;
      setScrollPosition(position);
    }
  };

  return (
    <div className="aml-list_wrapper">
      <div
        className={cn("aml-list", {
          "empty": !assets?.length,
          "scroll": scrollable,
          "borderTop": scrollable && borderTop,
          "borderBottom": scrollable && borderBottom,
        })}
        onScroll={handleScroll}
        ref={containerRef}
      >
        <List
          component={getList()}
          loading={fetching}
          preloader={<BNSpinner />}
          emptyText={<>
            <Button
              title={t("amlPage.addWallet")}
              onClick={onClickAddWallet}
              className="aml-btn"
            />
            <h3 className="empty-text">{t("amlPage.emptyListOfWalletsText")}</h3>
          </>}
          data={assets}
        />
      </div>
      {!isMobile && <div className="aml-list_line" />}
    </div>
  );
};

export default AMLPageWrapperWallets;