import { ETariffPlan } from "../store/services/types";
import i18n from "../i18n";

export const getCurrentPlan = (tariffPlan: ETariffPlan|undefined): string => {
  switch (tariffPlan) {
  case ETariffPlan.smart:
    return i18n.t("pricing.smart");
  case ETariffPlan.pro:
    return i18n.t("pricing.pro");
  default:
    return i18n.t("pricing.newbie");
  }
};