import { FC } from "react";
import { useTranslation } from "react-i18next";

import HeaderChecksLeft from "components/segments/NewHeader/components/HeaderChecksLeft";

const AMLPageWrapperChecks: FC = () => {
  const { t } = useTranslation();
  return <div className="aml-list__checks">
    <span className="aml-list__checks__text">{t("amlPage.accountsRiskMonitoring")}</span>
    <HeaderChecksLeft/>
  </div>;
};

export default AMLPageWrapperChecks;
