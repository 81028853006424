{/* eslint-disable max-len */}
import { AxiosError } from "axios";
import { OutputSelector } from "reselect";

import { ECurrency, ETransactionsOperationsTypes, TNullable } from "config/types";

import { IApplicationState, IPaginationResponse, TRequestHandler } from "store/rootInterface";

import { TAmountAsset } from "utils/transactionUtils";

import { TTransactionsFilterState } from "../filter/types";
import { ESubTypes } from "../assets/types";

export type TEditModalFormState = {
  title?: TNullable<string>;
  changeType?: boolean;
  external_amount?: TNullable<number>;
  inputAmount?: TNullable<number>;
  inputAsset?: TNullable<string>;
  outputAmount?: TNullable<number>;
  outputAsset?: TNullable<string>;
  fio?: TNullable<string>;
  date?: TNullable<string>;
}

export type TTransactionBlocksType = {
  leftBlock: ETransactionOperationType,
  rightBlock: ETransactionOperationType
}

export type TTransactionMenuList = {
  value: string,
  label: string
};

export enum BtnIconColors {
  blue = "blue",
  red = "red",
  gray = "gray",
  green = "green",
}
export type TBtnIconColors = {
  editIcon: TNullable<BtnIconColors>,
  fileIcon: TNullable<BtnIconColors>,
}


export enum ETransactionOperationType {
  internal = "internal",
  external = "external"
}

export type TTransactionInfo = {
  logoType: TNullable<string>;
  transactionName: string;
  changeTypeList: TNullable<TTransactionMenuList[]>;
  transactionNameAction: TNullable<boolean>;
  subName?: string;

  btnIconColors: TBtnIconColors;
  showBtns?: boolean;
  error: TNullable<string>;
  modalState?: TNullable<TEditModalFormState>;
  isIncomingOperation: TNullable<boolean>;
  isMarginTrading?: boolean;
  isLoss?: boolean;

  needsDetails?: TNullable<boolean>
  classify?: boolean

  inputTitle?: TNullable<string>
  inputSubTitle: TNullable<string>
  inputAmount: TNullable<number>
  inputAsset: TNullable<string>
  inputName: TAmountAsset
  inputBlock: ETransactionOperationType
  inputAssetVerified: TNullable<boolean>;
  inputAssetIcon: TNullable<string>;

  outputTitle?: TNullable<string>
  outputSubTitle: TNullable<string>
  outputAmount: TNullable<number>
  outputAsset: TNullable<string>
  outputName: TAmountAsset
  outputBlock: ETransactionOperationType
  outputAssetVerified: TNullable<boolean>;
  outputAssetIcon: TNullable<string>;
};

export type TTransactionParts = {
  amount: number
  asset: string
  cost: TNullable<number>
  asset_verified: boolean
  asset_icon: TNullable<string>
}
export type TTransactionOperation = {
  account_id: TNullable<string> //
  account_name: TNullable<string> // - имя аккаунта операции
  // amount: TNullable<number>
  // asset: TNullable<string>
  parts: TTransactionParts[] // - составные части операции.
  cost: TNullable<number> // - общая стоимость активов в пересчете на cost_currency. Может быть null - тогда не показываем.
  sub_account_type: TNullable<ESubTypes> // - тип суб-счета аккаунта операции
  exchange_symbol?: TNullable<string>;
  exchange_rate?: TNullable<string>;
}

export enum ETransactionExtOperationDetails {
  available = "available",
  required = "required"
}
export enum ETransactionExtOperationFiles {
  none = "none",
  required = "required",
  saved = "saved"
}
export type TTransactionExtOperation = {
  account_id: TNullable<string>
  account_name: TNullable<string>
  address: TNullable<string>// - адрес внешнего кошелька. Может быть null. Нужен для подписи.
  amount: TNullable<number> // - кол-во актива операции. Может быть null.
  asset: TNullable<string> // - актив операции. Может быть null.
  cost: TNullable<number>
  counterparty: TNullable<string> // - имя контрагента. Может быть null.
  details:  ETransactionExtOperationDetails // - статус дополнительно требующейся информации. Возможные варианты: available, required
  files:  ETransactionExtOperationFiles // - статус прикрепленных файлов. Возможные значения: none (нет файлов), required (требуются файлы), saved (все файлы загружены).
  sub_account_type: TNullable<ESubTypes>
  asset_verified: boolean
  asset_icon: TNullable<string>
}

export type TTransactionPosition = {
  account_id: TNullable<string> // - ID аккаунта.
  account_name: TNullable<string> // - название аккаунта.
  symbol: TNullable<string> // - "символ" торгового инструмента.
  symbol_icon: TNullable<string> // - актив, используемый для обозначения иконки символа (например, BTC для BTC-PERP или BTCUSDT). Здесь именно возвращаем не ссылку, а код валюты.
  base_asset: TNullable<string> // - базовая валюта актива.
  quote_asset: TNullable<string> // - валюты расчета.
  amount: TNullable<string> // - размер позиции. Отрицательное значение - шорт, положительное - лонг.
  opening_price: TNullable<string> // - цена открытия позиции. Присутствует всегда, если передается позиции.
  closing_price: TNullable<string> // - цена закрытия позиции. Передается лишь при закрытии позиции.
  closing_pnl: TNullable<string> // - сумма дохода (расхода) в валюте расчета.
}

export type TTransactionResult = {
  id: string
  type: ETransactionsOperationsTypes
  datetime: string

  input: TNullable<TTransactionOperation>
  input_ext: TNullable<TTransactionExtOperation>
  output: TNullable<TTransactionOperation>
  output_ext: TNullable<TTransactionExtOperation>
  position: TNullable<TTransactionPosition>
  cost_currency: ECurrency

  fee_asset: TNullable<string>
  fee_amount: TNullable<number>

  note: TNullable<string>
  tags: [{ name: string }]

  checked?: boolean // custom field to tract checking transaction on checkbox group

  formedInfo: TTransactionInfo;  // Данные сформированные после получения из API
};

export type TTransactionsData = IPaginationResponse<TTransactionResult>;

export type ITransactionList = {
  data: TTransactionResult[];
  requestedFilter: TNullable<IGetTransactionsListRequest>
  lastElementFromPreviousPage: TNullable<TTransactionResult>;
  firstElementOfNextPage: TNullable<TTransactionResult>;
  fetching: boolean;
  finish: boolean;
  infiniteScroll?: boolean;
  next: TNullable<number>;
  count: TNullable<number>;
  size?: number;
  error?: AxiosError;
}

export type TTransactionState = TRequestHandler<TTransactionResult>


export interface IEditModal {
 id: TNullable<string>;
 title: TNullable<string>;
 date: TNullable<string>;
 type: TNullable<string>;
 isOpen?: boolean;
 changeType?: boolean;
 formState?: TNullable<TEditModalFormState>;
}

export type TEditConfirm = {
  title: TNullable<string>;
  id: TNullable<string>;
  type: TNullable<string>;
  isOpen?: boolean;
}

export type TSingleTransactionData = {
  new_type: string;
  external_counterparty: string;
  external_amount: number;
  external_asset: string;
  original_ext_amount: number;
  original_ext_asset: string;
  external_foreign: boolean;
  external_source_country: TNullable<string>;
  external_destination_country: TNullable<string>;
}

export type TEditSingleTransactionData = {
  external_counterparty?: string;
  external_amount?: number;
  external_asset?: string;
  new_type?: TNullable<string>,
  external_foreign?: TNullable<boolean>,
  external_source_country?: TNullable<string>,
  external_destination_country?: TNullable<string>,
}

export type TCreateSingleTransactionData = {
  fio?: string;
  type?: string;
  date: string;
  src_asset: string;
  src_amount: number;
  dst_asset: string;
  dst_amount: number;
  external_foreign: boolean;
  external_source_country: TNullable<string>;
  external_destination_country: TNullable<string>;
}

export type TUploadSingleTransactionFilesData = {
  file: string;
  name: string;
}

export type TSingleTransactionFilesListData = {
  id: string;
  operation_details: string;
  name: string;
  purpose: ETransactionsFilePurpose;
  file: string;
}

export type TTransactionDeleteFilesData = {
  files: string[]
}

export type TEditSingleTransactionPayload<T> = {
  id: string;
  values: T;
  deleteFiles?: string[];
  files?: ITransactionFilePayload[];
  closeIncomingOperationModal?: () => void;
}

export type TDeleteManualTransactionPayload = {
  id: string,
  reportId?: string,
  callback?: () => void
}

export type TDownloadSingleTransactionFilesPayload = {
  id: string,
  file: File;
  name: string;
  purpose: string;
  onProgress?: (event: ProgressEvent, id: string) => void;
}

export type TSingleTransaction = TRequestHandler<TSingleTransactionData>

export type TDeleteManualTransactionState = TRequestHandler<{detail: string}>

export type TEditSingleTransaction = TRequestHandler<TEditSingleTransactionData>

export type TEditManualTransaction = TRequestHandler<TEditSingleTransactionData>

export type TCreateManualTransaction = TRequestHandler<TCreateSingleTransactionData>

export type TCreateManualTransactionPayload = {
  finalCall: () => void;
  values: TCreateSingleTransactionData;
  files: ITransactionFilePayload[];
}

export type TUploadSingleFileTransaction = TRequestHandler<TUploadSingleTransactionFilesData> & {
  progress: number;
}

export type TSingleTransactionFilesList = TRequestHandler<TSingleTransactionFilesListData[]>

export type TTransactionDeleteFilesOptions = {
  id: string,
  files: string[]
}

export type TTransactionDeleteFiles = TRequestHandler<TTransactionDeleteFilesData>

export interface IGetTransactionsListRequest extends TTransactionsFilterState {
  offset?: number;
  limit?: number;
  infiniteScroll?: boolean;
  callback?: (v?: any) => void;
}

export type TGetTransactionsListSuccess = {
  infiniteScroll?: boolean;
  limit: number;
  offset: number;
} & TTransactionsData

export type TTransactionOutputSelector<T> = OutputSelector<IApplicationState, T, (s: TTransactionStoreState) => T>;


export enum ETransactionsFilePurpose {
  purchaseTrade = "purchase_trade",
  purchasePayment = "purchase_payment",
  airdropAddress = "airdrop_address",
  airdropParticipation = "airdrop_participation",
  forkOccurred = "fork_occurred",
  forkAssetOwnership = "fork_asset_ownership",
  loanAgreement = "loan_agreement",
  serviceAgreement = "service_agreement",
  myTransferAddressOwnership = "my_transfer_address_ownership",
  miningActivty = "mining_activity",
  miningReward = "mining_reward",
  stakingActivity = "staking_activty",
  stakingReward = "staking_reward",
  stakingReturn = "staking_return",
  lendingActivity = "lending_activty",
  lendingReward = "lending_reward",
  lendingReturn = "lending_return",
  p2eActivity = "p2e_activity",
  p2eReward = "p2e_reward",
  giftAgreement = "gift_agreement",
  otherRewardReason = "other_reward_reason",
}

export interface ITransactionFilePayload {
  src: File;
  name: string;
  purpose: TNullable<ETransactionsFilePurpose>;
}

export interface IUploadMultipleTransactionFilesPayload {
  id: string;
  files: ITransactionFilePayload[];
  onProgress?: (event: ProgressEvent, id: string) => void;
  closeIncomingOperationModal?: () => void;
}

export type TEditTransactionNotePayload = {
  id: string;
  accountId?: string;
  data: {
    note: string;
  }
  callOnSuccess?: (values?: any) => void;
}
export type TEditTransactionNoteData = {
  note: string;
}
export type TEditTransactionNoteState = TRequestHandler<TEditTransactionNoteData>

export type TEditTransactionTagPayload = {
  id: string;
  accountId?: string;
  data: {
    tags: [{name: string}]
  }
  callOnSuccess?: (values?: any) => void;
}
export type TEditTransactionTagData = [{ name: string }]
export type TEditTransactionTagState = TRequestHandler<TEditTransactionTagData>


export type TTransactionStoreState = {
  list: ITransactionList;
  transaction: TTransactionState;
  editModal: IEditModal;
  addModal: boolean;
  addModalType: TNullable<string>;
  editConfirm: TEditConfirm;
  singleTransactionState: Record<string, TSingleTransaction>;
  deleteManualTransactionState: Record<string, TDeleteManualTransactionState>;
  editSingleTransactionState: Record<string, TEditSingleTransaction>;
  createManualTransactionState: TCreateManualTransaction;
  editManualTransactionState: Record<string, TEditManualTransaction>;
  uploadSingleTransactionFilesState: Record<string, TUploadSingleFileTransaction>;
  singleTransactionFilesState: Record<string, TSingleTransactionFilesList>;
  deleteTransactionFilesState: TTransactionDeleteFiles;
  editTransactionNoteState: TEditTransactionNoteState;
  editTransactionTagState: TEditTransactionTagState;
  addModalStep: number;
};

export type TTransactionsOutputSelector<T> = OutputSelector<IApplicationState, T, (s: TTransactionStoreState) => T>;
