import { FC, useEffect, useState } from 'react';
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { maxMobileMediaWidth, QUERIES } from "config/constants";

import useQuery from "hooks/useQuery";
import { ELimitations } from "hooks/usePlanLimitation";

import { modalStateSelector } from "store/modals/selectors";
import { EModals } from "store/modals/types";
import { closeModal } from "store/modals/reducers";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";
import { EReportType } from "store/reports/types";

import UpgradePlanNote from './components/UpgradePlanNote';
import UpgradePlanPricing from "./components/UpgradePlanPricing";
import UpgradePlanPricingMobile from './components/UpgradePlanPricingMobile';

export type TUpgradePlanProps = {
  className?: string
}

const UpgradePlan: FC<TUpgradePlanProps> = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showNote, setShowNote] = useState(true);
  const visible = useSelector(modalStateSelector(EModals.upgradePlan));
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const query = useQuery();
  const limit = query.get(QUERIES.limit);
  const reportType = query.get(QUERIES.reportType) as EReportType|undefined;

  const hideShowNote = limit === ELimitations.directToPlan;

  const onCancel = () => {

    query.delete(QUERIES.limit);
    query.delete(QUERIES.reportType);
    history.replace({
      search: query.toString(),
    });

    if (!showNote) {
      dispatch(analyticEvent(EEventType.PRICING_POPUP_CLOSE));
    } else {
      dispatch(analyticEvent(EEventType.UPGRADE_PLAN_CLOSE));
    }

    dispatch(closeModal(EModals.upgradePlan));
    setShowNote(true);
  };

  const fromText = () => {
    switch (limit) {
    case ELimitations.note:
      return t("upgradePlan.noteLimitText");
    case ELimitations.draft:
      if (reportType === EReportType.unified_tax_report) {
        return t("upgradePlan.draftUnifiedTaxReportModeText");
      } else {
        return t("upgradePlan.draftModeText");
      }
    case ELimitations.limit_35_days:
      return t("upgradePlan.limit35Days");
    case ELimitations.aml_checks:
      return t("upgradePlan.amlChecksText");
    case ELimitations.limit_1000_txs:
      return t("upgradePlan.limit1000txsText");
    default:
      return "";
    }
  };

  useEffect(() => {
    if (visible) {
      dispatch(analyticEvent(EEventType.UPGRADE_PLAN_POPUP));
    }
  }, [visible]);

  useEffect(() => {
    if (limit && limit === ELimitations.directToPlan) {
      setShowNote(false);
    }
  }, [limit]);

  return (
    <Modal
      visible={visible}
      title={false}
      footer={false}
      centered={true}
      className={cn("upgrade-plan", className, { "show-header": !showNote })}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      closeIcon={<></>}
      onCancel={onCancel}
      width={588}
    >
      {!hideShowNote && <UpgradePlanNote
        text={fromText()}
        setShowNote={setShowNote}
        closeModal={onCancel}
        showNote={showNote}
      />}
      {!isMaxMobile && <UpgradePlanPricing showPlan={!showNote} closeModal={onCancel}/>}
      {(isMaxMobile && !showNote) &&
        <UpgradePlanPricingMobile
          closeModal={onCancel}
        />
      }
    </Modal>
  );
};

export default UpgradePlan;
