import { FC } from 'react';
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import BNRadio from "components/BNRadio";

import { TUserPaymentMethodsData } from "store/user/types";

import { analyticEvent } from "../../../../../../store/analytics/effects";
import { EEventType } from "../../../../../../store/analytics/types";


export type TPricingPaymentMethodsProps = {
  setPaymentMethod: (val: any) => void
  loading?: boolean
  selected: TUserPaymentMethodsData
  paymentMethods: TUserPaymentMethodsData[]
}
const PricingPaymentMethods: FC<TPricingPaymentMethodsProps> = ({ 
  loading, paymentMethods, setPaymentMethod , selected
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const error = false;  // TODO: To show error
  const formValue = (method: TUserPaymentMethodsData) => method.type + "/" + method.id;

  return (
    <div className="pricing__activate__radio__wrapper">
      <BNRadio.Group
        disabled={loading}
        name="payment_method"
        value={formValue(selected)}
      >
        {
          paymentMethods.map((option, index) => (
            <BNRadio
              name={`payment_method.${index}`}
              key={option.id || option.type}
              value={formValue(option)}
              className={cn("pricing__activate__radio", { error })}
              onChange={e => {
                dispatch(analyticEvent(EEventType.PAYMENT_STEP2_METHOD_CHANGED));
                setPaymentMethod(e.target.value);
              }}
            >
              {option.name}
              {error && <span className="pricing__activate__radio__error">
                {t("pricing.paymentFailed")}
              </span>}
            </BNRadio>
          ))
        }
      </BNRadio.Group>
    </div>
  );
};

export default PricingPaymentMethods;