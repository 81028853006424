import { OutputSelector } from "reselect";

import { TNullable } from "../../config/types";

import { IApplicationState, IPaginationResponse, TRequestHandler } from "../rootInterface";

export enum EAmlSingleStateCheckStatus {
  not_checked = "not_checked",
  pending = "pending",
  checked = "checked"
}

export type TAmlSingleLowRiskDetails = {
  miner: number;
  wallet: number;
  payment: number;
  marketplace: number;
  exchange_mlrisk_low: number;
  p2p_exchange_mlrisk_low: number;
  exchange_mlrisk_moderate: number;
}
export type TAmlSingleMediumRiskDetails = {
  atm: number;
  exchange_mlrisk_high: number;
  exchange_mlrisk_veryhigh: number;
  p2p_exchange_mlrisk_high: number;
}
export type TAmlSingleRiskDetails = {
  scam: number;
  mixer: number;
  ransom: number;
  gambling: number;
  dark_market: number;
  dark_service: number;
  stolen_coins: number;
  illegal_service: number;
  exchange_fraudulent: number;
}
export type TAmlSingleStateData = {
  check_status: EAmlSingleStateCheckStatus;
  last_checked: TNullable<string>;
  last_check_id: TNullable<string>;
  risk_score: number; // 0 - 1
  new_transactions: TNullable<number>;
  low_risk_value: number;
  medium_risk_value: number;
  high_risk_value: number;
  unknown_value: number;
  low_risk_details: TAmlSingleLowRiskDetails,
  medium_risk_details: TAmlSingleMediumRiskDetails,
  high_risk_details: TAmlSingleRiskDetails
}
export type TAmlSingleStateState = TRequestHandler<TAmlSingleStateData>

export type TAmlSingleCheckState = TRequestHandler<unknown>


export type TAmlSingleChecksOptions = {
  account?: string,
  manual?: boolean,
  archived?: boolean,
  offset?: TNullable<number>,
  limit?: number,
  loadMore?: boolean
}
export enum TAmlSingleChecksSubject {
  addr = "addr",
  tx = "tx",
}
export type TAmlSingleChecksData = {
  id: string;
  network: string;
  subject: TAmlSingleChecksSubject;
  value: string;
  status: EAmlSingleStateCheckStatus;
  checked_at: string;
  risk_score: number;
  low_risk_value: number;
  medium_risk_value: number;
  high_risk_value: number;
  unknown_value: number;
  low_risk_details: TAmlSingleLowRiskDetails;
  medium_risk_details: TAmlSingleMediumRiskDetails;
  high_risk_details: TAmlSingleRiskDetails;
}
export type TAmlSingleChecksState = TRequestHandler<IPaginationResponse<TAmlSingleChecksData>>
  & { prevData: TNullable<IPaginationResponse<TAmlSingleChecksData>> }

export enum EAmlSingleIds {
  archived= "archived",
  manual= "manual",
}

export enum TAmlStateManualCheckStatuses {
  checking = "checking",
  ready = "ready",
}
export type TAmlStateData = {
  available_checks: number;
  manual_checks: number;
  archived_checks: number;
  manual_check_status: TAmlStateManualCheckStatuses;
}
export type TAmlStateState = TRequestHandler<TAmlStateData>

export type TAmlCheckManuallyData = TAmlSingleChecksData
export type TAmlCheckManuallyState = TRequestHandler<TAmlCheckManuallyData>

export type TAmlRiskSummaData = {
  accounts: number // число привязанных кошельков (для проверок)
  risk_score: TNullable<number> // общий риск (от 0 до 1) по привязанным кошелькам.
  alarm_message: TNullable<string> // строка, сообщение-предупреждение о рисках.
  account_to_check: TNullable<string> // id аккаунта, который должен быть проверен прежде всего.
}
export type TAmlRiskSummaryState = TRequestHandler<TAmlRiskSummaData>

export type AmlPublicSingleData = {
  id: string;
  network: string;
  network_name: string
  address: TNullable<string>
  tx_hash: TNullable<string>
  checked_at: string;
  risk_score: number;
  low_risk_value: number;
  medium_risk_value: number;
  high_risk_value: number;
  unknown_value: number;
  low_risk_details: TAmlSingleLowRiskDetails;
  medium_risk_details: TAmlSingleMediumRiskDetails;
  high_risk_details: TAmlSingleRiskDetails;
}
export type TAmlPublicSingleState = TRequestHandler<AmlPublicSingleData>

export type TAmlStoreState = {
  amlSingleStateState: Record<string, TAmlSingleStateState>;
  amlSingleCheckState: TAmlSingleCheckState;
  amlSingleChecksState: Record<string, TAmlSingleChecksState>;
  amlStateState: TAmlStateState;
  amlCheckManuallyState: TAmlCheckManuallyState
  amlRiskSummaryState: TAmlRiskSummaryState
  amlPublicSingleState: TAmlPublicSingleState
  amlActivateDropdown: boolean
}

export type TAmlOutputSelector<T> = OutputSelector<IApplicationState, T, (s: TAmlStoreState) => T>;
