import { createSelector, Dictionary } from "@reduxjs/toolkit";
import groupBy from "lodash/groupBy";

import { TNullable } from "config/types";

import {
  ReportTaxTransactionTypes,
  TCreateTaxReportStepsState,
  TCreateTransitionExport,
  TNewReportOptionData, TReportTransaction,
  TReportTransactionResult, TReportTransactionType,
  TSingleUnifiedTaxReportData, TUnifiedTaxReportFormState,
  TUnifiedTaxReportTransactionTypesState
} from "store/reports/types";

import { getGroupedTransactions } from "utils/transactionUtils";

import { IApplicationState } from "../rootInterface";

import { TReportsOutputSelector, TSingleTaxReportData } from "./types";

const selectState = (state: IApplicationState) => state.reports;

// eslint-disable-next-line max-len
export const getReportsFetchingSelector = createSelector(selectState, state => state.reportsState.fetching);
export const getReportsDataSelector = createSelector(selectState, state => state.reportsState.data);

export const taxReportResultStateSelector = createSelector(selectState, state => state.taxReportResultState);
export const taxReportResultFilesSelector = createSelector(selectState, state => {
  const files = state.taxReportResultState.data?.files || [];
  return groupBy(files, item => item.tag);
});

export const newReportFetchingSelector = createSelector(selectState, state => state.newReportState?.fetching);
export const newReportFailureSelector = createSelector(selectState, state => state.newReportState?.failure);

export const createReportModalSelector = createSelector(selectState, state => state.modalsState?.create);
export const successReportModalSelector = createSelector(selectState, state => state.modalsState?.success);
export const cancelReportModalSelector = createSelector(selectState, state => state.modalsState?.cancel);
export const draftReportModalSelector = createSelector(selectState, state => state.modalsState?.draft);
export const stepTwoUnconfirmedOperationModalSelector =
  createSelector(selectState, state => state.modalsState?.stepTwoUnconfirmedOperation);

export const getReportsTaxAmountDataSelector = createSelector(selectState, state => state.taxAmountReport.data);
export const getReportsTaxAmountDataOF2021Selector = createSelector(
  selectState, state => state.taxAmountReport.data?.find(item => item.year === 2021));
export const getReportsTaxAmountFetchingSelector = createSelector(
  selectState, state => state.taxAmountReport.fetching);
export const getReportsTaxAmountFailureSelector = createSelector(selectState, state => state.taxAmountReport.failure);

export const createTaxReportStepsSelector =
  (id: string|null): TReportsOutputSelector<TCreateTaxReportStepsState | null> =>
    createSelector(selectState, state => {
      if (!id) return null;
      return state.createTaxReportSteps[id];
    });

export const createTaxReportingProjectFetchingSelector =
  createSelector(selectState, state => state.createTaxReportingProject.fetching);

export const createUploadOperationsSelector =
  (id: string|null): TReportsOutputSelector<TCreateTransitionExport | null> =>
    createSelector(selectState, state => {
      if (!id) return null;
      return state.createTransitionExport[id];
    });

export const changeReportCheckStatusRequestSelector =
    createSelector(selectState, state => state.changeReportCheckStatus);

export const getSingleTaxReportDataSelector =
  (id: string): TReportsOutputSelector<TNullable<TSingleTaxReportData>> =>
    createSelector(selectState, state => state.singleTaxReport[id] && state.singleTaxReport[id].data);

export const createUnifiedTaxReportStateSelector =
    createSelector(selectState,
      state => state.createUnifiedTaxReportState);
export const updateUnifiedTaxReportStateSelector =
    createSelector(selectState,
      state => state.updateUnifiedTaxReportState);

export const unifiedTaxReportFormStateSelector =
  (id: string): TReportsOutputSelector<TNullable<TUnifiedTaxReportFormState>> =>
    createSelector(selectState,
      state => state.unifiedTaxReportFormState[id] || null);

export const getSingleUnifiedTaxReportDataSelector =
  (id: string): TReportsOutputSelector<TNullable<TSingleUnifiedTaxReportData>> =>
    createSelector(selectState,
      state => state.singleUnifiedTaxReport[id] && state.singleUnifiedTaxReport[id].data);

export const UnifiedTaxReportTransactionTypesStateSelector =
  (id: string): TReportsOutputSelector<TUnifiedTaxReportTransactionTypesState> =>
    createSelector(selectState,
      state => state.unifiedTaxReportTransactionTypesState[id]);

export const getSinglePersonalDataDataSelector =
  (id: string): TReportsOutputSelector<TNullable<TNewReportOptionData>> =>
    createSelector(selectState, state => state.singlePersonalData[id] && state.singlePersonalData[id].data);

export const reportTransactionSingleSelector = (id: string): TReportsOutputSelector<TNullable<TReportTransaction>> =>
  createSelector(
    selectState,
    state => {
      if (!id) return null;
      const singleReportTransaction = state.reportTransactions[id];
      if (!singleReportTransaction) return null;
      return singleReportTransaction;
    }
  );

export const reportTransactionsSelector = (id: string):
  TReportsOutputSelector<Dictionary<[...TReportTransactionResult[]]> | null> =>
  createSelector(
    selectState,
    state => {
      if (!id) return null;
      const singleReportTransaction = state.reportTransactions[id];
      if (!singleReportTransaction) return null;

      return getGroupedTransactions<TReportTransactionResult>(singleReportTransaction.data || []);
    }
  );

export const getReportTransactionsElement =
  (reportId: TNullable<string>, transactionId: TNullable<string>):
    TReportsOutputSelector<TNullable<TReportTransactionResult> | undefined> =>
    createSelector(selectState, state => {
      if (!reportId || !transactionId) return undefined;
      const singleReportTransaction = state.reportTransactions[reportId];
      if (!singleReportTransaction) return undefined;

      return singleReportTransaction.data.find(transaction => transaction.id === transactionId);
    });

export const reportTaxTransactionTypesSelector =
  (id: TNullable<string>): TReportsOutputSelector<TNullable<ReportTaxTransactionTypes[]>> =>
    createSelector(
      selectState,
      state => {
        if (!id) return null;
        const reportTaxTransactionTypesSingle = state.reportTaxTransactionTypesState[id];

        if (!reportTaxTransactionTypesSingle || !reportTaxTransactionTypesSingle.data) return null;
        return reportTaxTransactionTypesSingle.data.types;
      },
    );

export const detailRequiredTaxTransactionTypesSelector =
  (id: TNullable<string>): TReportsOutputSelector<TNullable<ReportTaxTransactionTypes> | undefined> =>
    createSelector(
      selectState,
      state => {
        if (!id) return null;
        const reportTaxTransactionTypesSingle = state.reportTaxTransactionTypesState[id];
        if (!reportTaxTransactionTypesSingle) return null;

        return reportTaxTransactionTypesSingle.data?.types.find(item => item.type === 'details_required');
      },
    );

export const reportTaxResultTransactionTypesSelector =
  (id: TNullable<string>): TReportsOutputSelector<TNullable<TReportTransactionType[]>> =>
    createSelector(
      selectState,
      state => {
        if (!id) return null;
        const reportTaxResultTransactionTypes = state.reportTaxResultTransactionTypesState[id];

        if (!reportTaxResultTransactionTypes || !reportTaxResultTransactionTypes.data) return null;

        return reportTaxResultTransactionTypes.data.types;
      }
    );

export const reportAgreementFetchingSelector = createSelector(
  selectState,
  state => state.reportAgreement.fetching,
);

export const createPersonalFailureSelector = createSelector(
  selectState,
  state => state.createPersonalData.failure,
);

export const createPersonalFetchingSelector = createSelector(
  selectState,
  state => state.createPersonalData.fetching,
);

export const createPersonalCompleteFetchingSelector = createSelector(
  selectState,
  state => state.createPersonalDataComplete.fetching,
);

export const updateTaxReportAccountsFetchingSelector = createSelector(
  selectState,
  state => state.updateTaxReportAccounts.fetching,
);

export const deleteReportFetchingSelector = createSelector(
  selectState,
  state => state.deleteReportState.fetching,
);

export const getReportSingleFilesSelector = createSelector(selectState, state => {
  const files = state.reportSingleState.data?.files || [];
  return groupBy(files, item => item.tag);
});
export const getReportSingleSelector = createSelector(selectState, state => state.reportSingleState);
export const createTransactionExportSelector = createSelector(selectState,
  state => state.createTransactionExportState);
export const createSourcesExportSelector = createSelector(selectState, state => state.createSourcesExportState);
export const reportsTypesSelector = createSelector(selectState, state => state.reportsTypesState);
