import { FC, MutableRefObject } from 'react';
import { useSelector } from "react-redux";
import cn from "classnames";

import { QUERIES } from "config/constants";

import CarouselVectorIcon from "components/Icons/CarouselVectorIcon";

import useQuery from "hooks/useQuery";

import {
  paymentCalculateDataSelector,
} from "store/services/selectors";
import { ETariffPlan, TServiceDetails } from "store/services/types";

import PriceActivateMainInfoBody from "../PriceActivateMainInfoBody";

export type TPriceActivateMainInfoBodyProps = {
  wrapRef: MutableRefObject<HTMLDivElement|null>
  service: TServiceDetails
  showVector?: boolean
  vectorActive?: boolean
  setVectorToggle?: () => void
}

const PriceActivateMainInfo: FC<TPriceActivateMainInfoBodyProps> = ({
  wrapRef, showVector, vectorActive, setVectorToggle, service
}) => {
  const calculateData = useSelector(paymentCalculateDataSelector);
  const query = useQuery();
  const tariff = query.get(QUERIES.tariff) as ETariffPlan;

  return (
    <div
      ref={wrapRef}
      className={cn("pricing__activate__main-info", { vector: showVector, open: showVector && vectorActive })}
      onClick={setVectorToggle}
    >
      {service?.code && <PriceActivateMainInfoBody
        tariff={tariff}
        code={service?.code}
        currency={!calculateData ? service?.currency : calculateData.currency}
        monthlyPayment={service?.monthly_payment}
        price={!calculateData ? Number(service?.price) : Number(calculateData.original_price)}
      />}
      {showVector && <div className="pricing__activate__main-info__vector">
        <CarouselVectorIcon className={cn({ active: vectorActive })}/>
      </div>}
    </div>
  );
};

export default PriceActivateMainInfo;
