import { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";

import { mobileMediaWidth } from "config/constants";

import BNSpinner from "components/BNSpinner";

import {
  getAssetsDataSelector,
  getMyAssetsSelector
} from "store/assets/selectors";
import { TMyAssetsData } from "store/assets/types";
import { getAssetsRequest } from "store/assets/reducers";

import { filterListBySearch } from "utils/filters";

import { IAssetsSearchProps } from "../../types";

import AssetsListItem from "../AssetsListItem";

const Items: FC<{ items: TMyAssetsData[] }> = ({ items }) => {
  const { t } = useTranslation();
  if (!!items?.length) {
    return (
      <>
        {items?.map(item => (
          <AssetsListItem key={item.id} {...item} />
        ))}
      </>
    );
  } else return <h3 className="empty-text">{t("assetsList.emptyText")}</h3>;
};

const AssetsList: FC<IAssetsSearchProps> = ({ searchValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: `(max-width: ${mobileMediaWidth}px)` });
  const { data, fetching, isSetStatus } = useSelector(getMyAssetsSelector);
  const assets = useSelector(getAssetsDataSelector);
  const filteredAssets = useMemo(
    () => filterListBySearch<TMyAssetsData>(data, searchValue, "name"), [data, searchValue]);

  useEffect(() => {
    if (!assets?.length) {
      dispatch(getAssetsRequest({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="assets-list_wrapper">
      <div className="assets-list">
        {(fetching && isSetStatus === false)
          ? <BNSpinner />
          : isEmpty(data)
            ?  <h3 className="empty-text">{t("assetsList.emptyText2")}</h3>
            : <Items items={filteredAssets}/>
        }
      </div>
      {!isMobile && <div className="assets-list_line" />}
    </div>
  );
};

export default AssetsList;
