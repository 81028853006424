import { FC, MouseEvent } from 'react';
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { getAMLSingleId, getAMLSinglePath } from "config/paths";
import { TNullable } from "config/types";

import FormedRiskIcon from "components/FormedRiskIcon";

import { EEventType } from "store/analytics/types";
import { analyticEvent } from "store/analytics/effects";

import { prepareRiskValue } from "utils/risks";

export type TAMLPageWrapperWalletsItemProps = {
  id: string,
  name: string,
  source: string,
  risk: number,
  transactionsCount: TNullable<number>,
  icon: string
}

const AMLPageWrapperWalletsItem: FC<TAMLPageWrapperWalletsItemProps> = ({
  id, name, source, risk, transactionsCount, icon
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const amlSingleId = getAMLSingleId();

  const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (id !== amlSingleId) {
      dispatch(analyticEvent(EEventType.AML_SHOW_ACCOUNT_INFO));
      history.push(
        getAMLSinglePath(id)
      );
    }
  };

  return (
    <a
      className={cn("aml-item", { active: id === amlSingleId })}
      key={id}
      onClick={onClick}
    >
      <img src={icon} alt="aml-icon" className="aml-item-logo" />
      <div className="aml-item__naming">
        <h3 className="aml-item__name">
          {name}
        </h3>
        {source && <span className="aml-item__subname">{source}</span>}
      </div>
      <div className="aml-item__data">
        <div className="aml-item__risk">
          <FormedRiskIcon risk={risk} className="aml-item__risk__icon" />
          {t("amlPage.risk")}: {prepareRiskValue(risk)}%
        </div>
        {!!transactionsCount && <div className="aml-item__transactions">
          {t("amlPage.newTransactions")} ({transactionsCount})</div>}
      </div>
    </a>
  );
};

export default AMLPageWrapperWalletsItem;
