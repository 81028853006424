import { FC } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import { ETariffPlan } from "store/services/types";
import { userPlanInfoSelector } from "store/user/selectors";

import { usePricingContext } from "../../hooks";

import PricingPlanFree from "../PricingPlanFree";
import PricingPlanPro from "../PricingPlanPro";
import PricingPlanSmart from "../PricingPlanSmart";

const PricingCards: FC = () => {
  const { data: userPlan } = useSelector(userPlanInfoSelector);
  const { isModal } = usePricingContext();
  const tariffPlan = userPlan?.current_plan.tariff_plan;
  
  return (
    <div className={cn("pricing__cards", { modal: isModal })}>
      <PricingPlanFree active={tariffPlan === ETariffPlan.free}/>
      <PricingPlanSmart active={tariffPlan === ETariffPlan.smart}/>
      <PricingPlanPro active={tariffPlan === ETariffPlan.pro}/>
    </div>
  );};

export default PricingCards;