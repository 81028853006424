import { createSlice } from "@reduxjs/toolkit";

import { defaultState } from "../constants";

import { TAmlStoreState } from "./types";

const initialState: TAmlStoreState = {
  amlSingleStateState: {},
  amlSingleCheckState: defaultState,
  amlSingleChecksState: {},
  amlStateState: defaultState,
  amlCheckManuallyState: defaultState,
  amlRiskSummaryState: defaultState,
  amlPublicSingleState: defaultState,
  amlActivateDropdown: false
};

const amlSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    clearAmlState() {
      return initialState;
    },

    amlSingleStateRequest(state, action) {
      state.amlSingleStateState = {
        ...state.amlSingleStateState,
        [action.payload.id]: {
          ...state.amlSingleStateState[action.payload.id],
          fetching: true,
        },
      };
    },
    amlSingleStateSuccess(state, action) {
      state.amlSingleStateState = {
        ...state.amlSingleStateState,
        [action.payload.id]: {
          fetching: false,
          data: action?.payload.data,
          failure: null,
        },
      };
    },
    amlSingleStateFailure(state, action) {
      state.amlSingleStateState = {
        ...state.amlSingleStateState,
        [action.payload.id]: {
          fetching: false,
          data: null,
          failure: action?.payload.error,
        },
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlSingleCheckRequest(state, _) {
      state.amlSingleCheckState = {
        ...state.amlSingleCheckState,
        fetching: true,
      };
    },
    amlSingleCheckSuccess(state, action) {
      state.amlSingleCheckState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlSingleCheckFailure(state, action) {
      state.amlSingleCheckState = {
        ...state.amlSingleCheckState,
        fetching: false,
        failure: action.payload,
      };
    },

    amlSingleChecksRequest(state, action) {
      state.amlSingleChecksState = {
        ...state.amlSingleChecksState,
        [action.payload.id]: {
          ...state.amlSingleChecksState[action.payload.id],
          fetching: true,
        },
      };
    },
    amlSingleChecksSuccess(state, action) {
      state.amlSingleChecksState = {
        ...state.amlSingleChecksState,
        [action.payload.id]: {
          fetching: false,
          data: action.payload.data,
          prevData: state.amlSingleChecksState[action.payload.id]?.data || null,
          failure: null,
        },
      };
    },
    amlSingleChecksLoadMore(state, action) {
      const list = state.amlSingleChecksState[action.payload.id]?.data?.results || [];

      state.amlSingleChecksState = {
        ...state.amlSingleChecksState,
        [action.payload.id]: {
          ...action.payload.data,
          results: [
            ...list,
            ...action.payload.data.results
          ]
        },
      };
    },
    amlSingleChecksFailure(state, action) {
      state.amlSingleChecksState = {
        ...state.amlSingleChecksState,
        [action.payload.id]: {
          ...state.amlSingleChecksState[action.payload.id],
          fetching: false,
          failure: action.payload.error,
        },
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlStateRequest(state, _) {
      state.amlStateState = {
        ...state.amlStateState,
        fetching: true,
      };
    },
    amlStateSuccess(state, action) {
      state.amlStateState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlStateFailure(state, action) {
      state.amlStateState = {
        ...state.amlStateState,
        fetching: false,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlCheckManuallyRequest(state, _) {
      state.amlCheckManuallyState = {
        ...state.amlCheckManuallyState,
        fetching: true,
      };
    },
    amlCheckManuallySuccess(state, action) {
      state.amlCheckManuallyState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlCheckManuallyFailure(state, action) {
      state.amlCheckManuallyState = {
        ...state.amlCheckManuallyState,
        fetching: false,
        failure: action.payload,
      };
    },

    amlRiskSummaryRequest(state) {
      state.amlRiskSummaryState = {
        ...state.amlRiskSummaryState,
        fetching: true,
      };
    },
    amlRiskSummarySuccess(state, action) {
      state.amlRiskSummaryState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRiskSummaryFailure(state, action) {
      state.amlRiskSummaryState = {
        ...state.amlRiskSummaryState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlPublicSingleRequest(state, _) {
      state.amlPublicSingleState = {
        ...state.amlPublicSingleState,
        fetching: true,
      };
    },
    amlPublicSingleSuccess(state, action) {
      state.amlPublicSingleState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlPublicSingleFailure(state, action) {
      state.amlPublicSingleState = {
        ...state.amlPublicSingleState,
        fetching: false,
        failure: action.payload,
      };
    },

    setAmlActivateDropdown(state, action) {
      state.amlActivateDropdown = action.payload;
    }
  }
});

export const {
  clearAmlState,

  amlSingleStateRequest,
  amlSingleStateSuccess,
  amlSingleStateFailure,

  amlSingleCheckRequest,
  amlSingleCheckSuccess,
  amlSingleCheckFailure,

  amlSingleChecksRequest,
  amlSingleChecksLoadMore,
  amlSingleChecksSuccess,
  amlSingleChecksFailure,

  amlStateRequest,
  amlStateSuccess,
  amlStateFailure,

  amlCheckManuallyRequest,
  amlCheckManuallySuccess,
  amlCheckManuallyFailure,

  amlRiskSummaryRequest,
  amlRiskSummarySuccess,
  amlRiskSummaryFailure,

  amlPublicSingleRequest,
  amlPublicSingleSuccess,
  amlPublicSingleFailure,

  setAmlActivateDropdown
} = amlSlice.actions;
export default amlSlice.reducer;
