import { FC, Fragment, useMemo, useRef } from "react";
import { Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import isFunction from "lodash/isFunction";
import { DropDownProps } from "antd/lib/dropdown/dropdown";

import { LANGUAGE_OPTIONS } from "config/constants";

import ArrowIcon from "components/Icons/ArrowIcon";

import { languageOption } from "utils/language";

import { onChangeLanguageHandler } from "../../i18n/utils";
import { ELanguages } from "../../i18n/constants";

export type TSelectLanguageProps = {
  clasName?: string
  renderInWrapper?: boolean;
  onChangeLng?: (lng: string) => void;
} & Pick<DropDownProps, "placement">

const SelectLanguage: FC<TSelectLanguageProps> = ({
  clasName, placement, renderInWrapper, onChangeLng
}) => {
  const { i18n } = useTranslation();
  const wrapRef = useRef<HTMLDivElement>(null);
  const activeLang = languageOption(i18n.language as ELanguages);

  const handleSetLanguage = (lng: string) => {
    onChangeLanguageHandler(lng);
    if (isFunction(onChangeLng)) onChangeLng(lng);
  };

  const menu = useMemo(() => (
    <Menu>
      {Object.keys(LANGUAGE_OPTIONS).map(lng => (
        <Menu.Item
          key={lng}
          onClick={() => {
            handleSetLanguage(lng);
          }}
        >
          {LANGUAGE_OPTIONS[lng as ELanguages].nativeName}
        </Menu.Item>
      ))}
    </Menu>
  ), []);

  if (process.env.REACT_APP_LANGUAGE) {
    return <Fragment/>;
  }

  return (
    <div className={cn("select-language", clasName)} ref={wrapRef}>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        placement={placement}
        className="select-language__dropdown__container"
        overlayClassName="select-language__dropdown"
        getPopupContainer={() => renderInWrapper ? wrapRef?.current || document.body : document.body}
      >
        <div><activeLang.icon/> {activeLang.nativeName} <ArrowIcon className="arrow"/></div>
      </Dropdown>
    </div>
  );
};

export default SelectLanguage;
