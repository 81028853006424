import assign from "lodash/assign";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import moment from "moment";

import { ECurrency, ETransactionsOperationsTypes, ETransactionsTypesTranslations, TNullable } from "config/types";

import { ETransactionChangeTypeAction, ETransactionsIcon, OutputTitle } from "components/Transactions/types";

import {
  BtnIconColors,
  ETransactionExtOperationDetails,
  ETransactionExtOperationFiles,
  ETransactionOperationType,
  TSingleTransactionFilesListData,
  TTransactionInfo,
  TTransactionOperation,
  TTransactionResult
} from "store/transactions/types";

import i18n from "../i18n";

import { abbreviateCurrency, formatExchangeRate } from "./fractions";
import { setDateTimeLocale } from "./dateHelpers";
import formatAssetAmount, { SignFormatAssetAmount } from "./formatAssetAmount";

export const TRANSACTION_NAME_WIDTH = 146;

interface Dictionary<T> {
  [Key: string]: T;
}

export const handleChangeTransactionType = (value: string): string => {
  switch (value) {
  case ETransactionsOperationsTypes.cryptoOutcomeSale:
  case ETransactionsOperationsTypes.cryptoIncomePurchase:
    return ETransactionChangeTypeAction.openModal;
  default:
    return ETransactionChangeTypeAction.openConfirm;
  }
};

export const getTransactionName = (value: string | undefined | null): string => {
  switch (value) {
  case ETransactionsOperationsTypes.manualCryptoPurchase:
    return i18n.t(ETransactionsTypesTranslations.cryptoPurchase);
  case ETransactionsOperationsTypes.manualCryptoSale:
    return i18n.t(ETransactionsTypesTranslations.cryptoSale);
  default: return "";
  }
};

export type TAmountAsset = JSX.Element|string
type TTransactionValue =
  Pick<TTransactionInfo, "transactionName"|"logoType"|"inputTitle"|"outputTitle"|"subName"|"showBtns"> &
  { inputName?: TAmountAsset }
type TIconHandler = (item: Pick<TTransactionResult, "input_ext" | "output_ext">) => BtnIconColors | null;
type TInputNameHandler = (
  item: Pick<TTransactionResult, "input" | "input_ext" | "output_ext">,
  sideData: Pick<ITransactionSideData, "inputAsset">
) => TAmountAsset;
type TOutputNameHandler = (
  sideData: Pick<ITransactionSideData, "outputAmount"|"outputAsset">
) => TAmountAsset;
type ITransactionInfoPart = Partial<TTransactionInfo>
type ITransactionSideData = {
  inputCost: TNullable<number>;
  inputAsset: TNullable<string>;
  inputAmount: TNullable<number>;
  inputAssetVerified: TNullable<boolean>;
  inputAssetIcon: TNullable<string>;
  outputCost: TNullable<number>;
  outputAsset: TNullable<string>;
  outputAmount: TNullable<number>;
  outputAssetVerified: TNullable<boolean>;
  outputAssetIcon: TNullable<string>;
}

// const responseItems: TTransactionResult[] = [];
// const mapper = new TransactionInfoMapper();
// const transactionItems = responseItems.map(mapper.map);
class TransactionInfoMapper {
  static INCOMING_OPERATION_LIST: ETransactionsOperationsTypes[] = [
    ETransactionsOperationsTypes.cryptoIncome,
    ETransactionsOperationsTypes.cryptoIncomeAirdrop,
    ETransactionsOperationsTypes.tradePositionPNL,
    ETransactionsOperationsTypes.cryptoIncomeFork,
    ETransactionsOperationsTypes.cryptoIncomeLoan,
    ETransactionsOperationsTypes.cryptoIncomePurchase,
    ETransactionsOperationsTypes.cryptoIncomeMyTransfer,
    ETransactionsOperationsTypes.cryptoIncomePayment,
    ETransactionsOperationsTypes.cryptoIncomeMining,
    ETransactionsOperationsTypes.cryptoIncomeStakingReward,
    ETransactionsOperationsTypes.cryptoIncomeStakingReturn,
    ETransactionsOperationsTypes.cryptoIncomeLendingReward,
    ETransactionsOperationsTypes.cryptoIncomeLendingReturn,
    ETransactionsOperationsTypes.cryptoIncomeP2e,
    ETransactionsOperationsTypes.cryptoIncomeGift,
    ETransactionsOperationsTypes.cryptoIncomeOtherReward,
    ETransactionsOperationsTypes.p2pPurchase,
    ETransactionsOperationsTypes.accountAirdrop,
  ];
  static TRANSACTION_STATIC_VALUES: Record<ETransactionsOperationsTypes, TTransactionValue> = {
    // Внутренние операции - tradeIcon
    [ETransactionsOperationsTypes.tradeSpot]: {
      transactionName: ETransactionsTypesTranslations.tradeSpot,
      logoType: ETransactionsIcon.tradeIcon,
    },
    [ETransactionsOperationsTypes.tradeOtc]: {
      transactionName: ETransactionsTypesTranslations.tradeOtc,
      logoType: ETransactionsIcon.tradeIcon,
    },
    [ETransactionsOperationsTypes.blockchainSwap]: {
      transactionName: ETransactionsTypesTranslations.blockchainSwap,
      logoType: ETransactionsIcon.tradeIcon,
    },
    [ETransactionsOperationsTypes.blockchainDex]: {
      transactionName: ETransactionsTypesTranslations.blockchainDex,
      logoType: ETransactionsIcon.tradeIcon,
    },
    // Внутренние операции - transferIcon
    [ETransactionsOperationsTypes.cryptoTransfer]: {
      transactionName: ETransactionsTypesTranslations.cryptoTransfer,
      logoType: ETransactionsIcon.transferIcon,
    },
    [ETransactionsOperationsTypes.innerTransfer]: {
      transactionName: ETransactionsTypesTranslations.innerTransfer,
      logoType: ETransactionsIcon.transferIcon,
    },
    [ETransactionsOperationsTypes.accountTransfer]: {
      transactionName: ETransactionsTypesTranslations.accountTransfer,
      logoType: ETransactionsIcon.transferIcon,
    },
    // Поступление криптовалюты внутри биржи/кошелька
    [ETransactionsOperationsTypes.p2pPurchase]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.p2pPurchase,
      logoType: ETransactionsIcon.arrowDown,
      inputTitle: "operations.myAccount",
    },
    [ETransactionsOperationsTypes.fiatIncome]: {
      transactionName: ETransactionsTypesTranslations.fiatIncome,
      logoType: ETransactionsIcon.arrowDown,
      inputTitle: "operations.myAccount",
    },
    [ETransactionsOperationsTypes.accountAirdrop]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.accountAirdrop,
      inputName: "transactionTypes.accountAirdrop_input",
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.tradePositionPNL]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.tradePositionPNL,
      inputName: "transactionTypes.tradePositionPNL_input",
      logoType: ETransactionsIcon.arrowDown,
    },
    // Поступление криптовалюты с внешних источников
    [ETransactionsOperationsTypes.cryptoIncome]: {
      transactionName: ETransactionsTypesTranslations.cryptoIncome,
      inputName: "operations.unknownFunds",
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomePurchase]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomePurchase,
      logoType: ETransactionsIcon.arrowDown,
      inputTitle: "operations.myAccount",
    },
    [ETransactionsOperationsTypes.cryptoIncomeAirdrop]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomeAirdrop,
      inputName: "transactionTypes.cryptoIncomeAirdrop_input",
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomeFork]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomeFork,
      inputName: "transactionTypes.cryptoIncomeFork_input",
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomeMyTransfer]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomeMyTransfer,
      inputName: "transactionTypes.cryptoIncomeMyTransfer_input",
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomeLoan]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomeLoan,
      inputName: "transactionTypes.cryptoIncomeLoan_input",
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomePayment]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomePayment,
      inputName: "transactionTypes.cryptoIncomePayment_input",
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomeMining]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomeMining,
      inputName: ETransactionsTypesTranslations.cryptoIncomeMining,
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomeStakingReward]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomeStakingReward,
      inputName: ETransactionsTypesTranslations.cryptoIncomeStakingReward,
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomeStakingReturn]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomeStakingReturn,
      inputName: ETransactionsTypesTranslations.cryptoIncomeStakingReturn,
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomeLendingReward]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomeLendingReward,
      inputName: ETransactionsTypesTranslations.cryptoIncomeLendingReward,
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomeLendingReturn]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomeLendingReturn,
      inputName: ETransactionsTypesTranslations.cryptoIncomeLendingReturn,
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomeP2e]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomeP2e,
      inputName: "transactionTypes.cryptoIncomeP2e_input",
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomeGift]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomeGift,
      inputName: ETransactionsTypesTranslations.cryptoIncomeGift,
      logoType: ETransactionsIcon.arrowDown,
    },
    [ETransactionsOperationsTypes.cryptoIncomeOtherReward]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoIncomeOtherReward,
      inputName: ETransactionsTypesTranslations.cryptoIncomeOtherReward,
      logoType: ETransactionsIcon.arrowDown,
    },
    // Вывод криптовалюты внутри биржи/кошелька
    [ETransactionsOperationsTypes.p2pSale]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.p2pSale,
      logoType: ETransactionsIcon.arrowUp,
      outputTitle: "operations.myAccount",
    },
    [ETransactionsOperationsTypes.fiatOutcome]: {
      transactionName: ETransactionsTypesTranslations.fiatOutcome,
      logoType: ETransactionsIcon.arrowUp,
      outputTitle: "operations.myAccount",
    },
    // Вывод криптовалюты на внешние аккаунты
    [ETransactionsOperationsTypes.cryptoOutcome]: {
      transactionName: ETransactionsTypesTranslations.cryptoOutcome,
      logoType: ETransactionsIcon.arrowUp,
      outputTitle: "operations.unknownWallet",
    },
    [ETransactionsOperationsTypes.cryptoOutcomeSale]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoSale,
      logoType: ETransactionsIcon.arrowUp,
      outputTitle: "operations.myWallet",
    },
    // Торговые сделки
    [ETransactionsOperationsTypes.tradePositionOpening]: {
      transactionName: ETransactionsTypesTranslations.tradePositionOpening,
      logoType: ETransactionsIcon.marginTradingOpening,
      subName: "operations.marginTrading",
    },
    [ETransactionsOperationsTypes.tradePositionClosing]: {
      transactionName: ETransactionsTypesTranslations.tradePositionClosing,
      logoType: ETransactionsIcon.marginTradingClosing,
      subName: "operations.marginTrading",
    },
    // Ручные операции
    [ETransactionsOperationsTypes.manualCryptoSale]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoSale,
      logoType: ETransactionsIcon.arrowUp,
      outputTitle: "operations.myAccount",
    },
    [ETransactionsOperationsTypes.manualCryptoPurchase]: {
      showBtns: true,
      transactionName: ETransactionsTypesTranslations.cryptoPurchase,
      logoType: ETransactionsIcon.arrowDown,
      inputTitle: "operations.myAccount",
    },
  };

  get typeMapper(): Record<string, (item: TTransactionResult) => ITransactionInfoPart | undefined> {
    return {
      // Внутренние операции - tradeIcon
      [ETransactionsOperationsTypes.tradeSpot]: this.tradeIcon,
      [ETransactionsOperationsTypes.tradeOtc]: this.tradeIcon,
      [ETransactionsOperationsTypes.blockchainSwap]: this.tradeIcon,
      [ETransactionsOperationsTypes.blockchainDex]: this.tradeIcon,
      // Внутренние операции - transferIcon
      [ETransactionsOperationsTypes.cryptoTransfer]: this.transferIcon,
      [ETransactionsOperationsTypes.innerTransfer]: this.transferIcon,
      [ETransactionsOperationsTypes.accountTransfer]: this.transferIcon,
      // Поступление криптовалюты внутри биржи/кошелька
      [ETransactionsOperationsTypes.p2pPurchase]: this.p2pPurchase,
      [ETransactionsOperationsTypes.accountAirdrop]: this.cryptoIncome,
      [ETransactionsOperationsTypes.tradePositionPNL]: this.cryptoIncome,
      // Поступление криптовалюты с внешних источников
      [ETransactionsOperationsTypes.cryptoIncome]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomePurchase]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomeAirdrop]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomeFork]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomeMyTransfer]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomeLoan]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomePayment]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomeMining]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomeStakingReward]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomeStakingReturn]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomeLendingReward]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomeLendingReturn]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomeP2e]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomeGift]: this.cryptoIncome,
      [ETransactionsOperationsTypes.cryptoIncomeOtherReward]: this.cryptoIncome,
      // Вывод криптовалюты внутри биржи/кошелька
      [ETransactionsOperationsTypes.p2pSale]: this.p2pSale,
      // Вывод криптовалюты на внешние аккаунты
      [ETransactionsOperationsTypes.cryptoOutcome]: this.cryptoOutcome,
      [ETransactionsOperationsTypes.cryptoOutcomeSale]: this.cryptoOutcomeSale,
      // Торговые сделки
      [ETransactionsOperationsTypes.tradePositionOpening]: this.tradePositionOpening,
      [ETransactionsOperationsTypes.tradePositionClosing]: this.tradePosition,
      // Ручные операции
      [ETransactionsOperationsTypes.manualCryptoSale]: this.manualP2pSale,
      [ETransactionsOperationsTypes.manualCryptoPurchase]: this.manualP2pPurchase,
    };
  }

  getTransactionSideData = (item: TTransactionResult): ITransactionSideData => {
    const { input, input_ext, output, output_ext } = item;
    return {
      inputCost: input?.cost || input_ext?.cost || 0,
      inputAsset: input?.parts[0].asset || input_ext?.asset || null,
      inputAmount: input?.parts[0].amount || input_ext?.amount || null,
      inputAssetVerified: input?.parts[0].asset_verified || input_ext?.asset_verified || null,
      inputAssetIcon: input?.parts[0].asset_icon || input_ext?.asset_icon || null,
      outputCost: output?.cost || output_ext?.cost || 0,
      outputAsset: output?.parts[0].asset || output_ext?.asset || null,
      outputAmount: output?.parts[0].amount || output_ext?.amount || null,
      outputAssetVerified: output?.parts[0].asset_verified || output_ext?.asset_verified || null,
      outputAssetIcon: output?.parts[0].asset_icon || output_ext?.asset_icon || null,
    };
  };
  getBaseInstance = (item: TTransactionResult): TTransactionInfo => {
    const { type, input_ext, input, output, output_ext } = item;
    const {
      inputAsset, inputAmount, inputAssetVerified, inputAssetIcon,
      outputAsset, outputAmount, outputAssetVerified, outputAssetIcon } = this.getTransactionSideData(item);
    const isMarginTrading = [
      ETransactionsOperationsTypes.tradePositionOpening,
      ETransactionsOperationsTypes.tradePositionClosing
    ].includes(type);

    const subName = get(TransactionInfoMapper.TRANSACTION_STATIC_VALUES, [type, "subName"], null);
    const inputTitle = get(TransactionInfoMapper.TRANSACTION_STATIC_VALUES, [type, "inputTitle"], null);
    const inputName = get(TransactionInfoMapper.TRANSACTION_STATIC_VALUES, [type, "inputName"], null);
    const outputTitle = get(TransactionInfoMapper.TRANSACTION_STATIC_VALUES, [type, "outputTitle"], null);
    return {
      // Кнопки редактирования и файлов
      showBtns: get(TransactionInfoMapper.TRANSACTION_STATIC_VALUES, [type, "showBtns"], false),
      btnIconColors: { // Цвета иконок
        editIcon: this.formEditIcon({ input_ext, output_ext }),
        fileIcon: this.formFileIcon({ input_ext, output_ext })
      },
      error: null,
      modalState: null,
      isIncomingOperation: TransactionInfoMapper.INCOMING_OPERATION_LIST.includes(type),
      needsDetails: false,
      classify: type === ETransactionsOperationsTypes.cryptoIncome,

      logoType: get(TransactionInfoMapper.TRANSACTION_STATIC_VALUES, [type, "logoType"], null),
      transactionName: get(TransactionInfoMapper.TRANSACTION_STATIC_VALUES, [type, "transactionName"], ""),
      transactionNameAction: null,
      subName: subName ? subName : undefined,
      changeTypeList: null,

      inputTitle: inputTitle ? inputTitle : (input?.account_name || input_ext?.account_name || ""),
      inputSubTitle: null,
      inputAsset,
      inputAmount,
      inputAssetVerified,
      inputAssetIcon,
      inputName: inputName ? inputName : this.formInputName(item, { inputAsset }),
      inputBlock: (input_ext && !isMarginTrading)
        ? ETransactionOperationType.external: ETransactionOperationType.internal,

      outputTitle: outputTitle ? outputTitle :
        (output?.account_name || output_ext?.account_name || ""),
      outputSubTitle: null,
      outputAsset,
      outputAmount,
      outputAssetVerified,
      outputAssetIcon,
      outputName: this.formOutputName({ outputAmount, outputAsset }),
      outputBlock: (output_ext && !isMarginTrading)
        ? ETransactionOperationType.external: ETransactionOperationType.internal,
    };
  }
  formEditIcon: TIconHandler = item => {
    const { input_ext, output_ext } = item;
    const extOperation = input_ext ? input_ext : output_ext;

    switch (extOperation?.details) {
    case ETransactionExtOperationDetails.available:
      return BtnIconColors.blue;
    case ETransactionExtOperationDetails.required:
      return BtnIconColors.red;
    default:
      return null;
    }
  };
  formFileIcon: TIconHandler = item => {
    const { input_ext, output_ext } = item;
    const extOperation = input_ext ? input_ext : output_ext;
    switch (extOperation?.files) {
    case ETransactionExtOperationFiles.saved:
      return BtnIconColors.green;
    case ETransactionExtOperationFiles.required:
      return BtnIconColors.red;
    case ETransactionExtOperationFiles.none:
      return BtnIconColors.gray;
    default:
      return null;
    }
  };
  formInputName: TInputNameHandler = (item, sideData) => {
    const { input, input_ext } = item;
    const { inputAsset } = sideData;
    return formatAssetAmount({
      amount: input ? input?.parts[0].amount : input_ext?.amount,
      asset: inputAsset,
      sign: SignFormatAssetAmount.MIN
    });
  };
  formOutputName: TOutputNameHandler = ({ outputAmount, outputAsset }) =>
    formatAssetAmount({ amount: outputAmount, asset: outputAsset, sign: SignFormatAssetAmount.PLS });

  map = (item: TTransactionResult): TTransactionInfo => {
    const { type } = item;
    const infoPartFn = get(this.typeMapper, type, undefined);
    return assign({}, this.getBaseInstance(item), infoPartFn && infoPartFn(item));
  };

  // Typed handlers
  // Внутренние операции - transferIcon
  tradeIcon = (item: TTransactionResult): ITransactionInfoPart => {
    const { input, cost_currency } = item;
    const { outputCost } = this.getTransactionSideData(item);

    return {
      inputSubTitle: this.getExchangeRateString(input),
      outputSubTitle: outputCost
        ? `≈ ${abbreviateCurrency(cost_currency || ECurrency.rub, outputCost)}`
        : ""
    };
  };
  // Внутренние операции - transferIcon
  transferIcon = (item: TTransactionResult): ITransactionInfoPart => {
    const { cost_currency } = item;
    const { outputCost } = this.getTransactionSideData(item);
    return {
      outputSubTitle: outputCost
        ? `≈ ${abbreviateCurrency(cost_currency || ECurrency.rub, outputCost)}`
        : ""
    };
  }
  // Поступление криптовалюты внутри биржи/кошелька
  p2pPurchase = (item: TTransactionResult): ITransactionInfoPart => {
    const { input } = item;
    return {
      modalState: {
        title: ETransactionsTypesTranslations.p2pPurchase,
        external_amount: input?.parts[0].amount
      },
      inputSubTitle: this.getExchangeRateString(input)
    };
  }
  // Поступление криптовалюты внутри биржи/кошелька
  // Поступление криптовалюты с внешних источников
  cryptoIncome = (item: TTransactionResult): ITransactionInfoPart => {
    const { input_ext, cost_currency } = item;
    const { outputCost } = this.getTransactionSideData(item);
    return {
      modalState: { title: "operations.incomingOperation" },
      transactionNameAction: true,
      inputSubTitle: input_ext?.address || "",
      outputSubTitle: outputCost
        ? `≈ ${abbreviateCurrency(cost_currency || ECurrency.rub, outputCost)}`
        : ""
    };
  }
  // Вывод криптовалюты внутри биржи/кошелька
  p2pSale = (item: TTransactionResult): ITransactionInfoPart => {
    const { input, output_ext } = item;
    return {
      inputSubTitle: this.getExchangeRateString(input),
      outputSubTitle: output_ext?.counterparty || OutputTitle.unknown
    };
  }
  // Вывод криптовалюты на внешние аккаунты
  cryptoOutcome = (item: TTransactionResult): ITransactionInfoPart => {
    const { output_ext, cost_currency } = item;
    const { inputCost } = this.getTransactionSideData(item);
    return {
      modalState: { title: "operations.incomingOperation" },
      changeTypeList: [
        {
          value: ETransactionsOperationsTypes.cryptoOutcomeSale,
          label: ETransactionsTypesTranslations.cryptoSale
        },
        {
          value: ETransactionsOperationsTypes.cryptoOutcome,
          label: ETransactionsTypesTranslations.cryptoOutcome
        }
      ],
      inputSubTitle: inputCost ? `≈ ${abbreviateCurrency(cost_currency || ECurrency.rub, inputCost)}` : "",
      outputSubTitle: output_ext?.address || ""
    };
  }
  cryptoOutcomeSale = (item: TTransactionResult): ITransactionInfoPart => {
    const { input, output_ext } = item;

    return {
      changeTypeList: [
        {
          value: ETransactionsOperationsTypes.cryptoOutcomeSale,
          label: ETransactionsTypesTranslations.cryptoSale
        },
        {
          value: ETransactionsOperationsTypes.cryptoOutcome,
          label: ETransactionsTypesTranslations.cryptoOutcome
        }
      ],
      inputSubTitle: this.getExchangeRateString(input),
      outputSubTitle: output_ext?.counterparty || OutputTitle.unknown
    };
  }
  // Торговые сделки
  tradePosition = (item: TTransactionResult): ITransactionInfoPart => {
    const { position, cost_currency } = item;
    const { outputCost } = this.getTransactionSideData(item);
    const isLoss = Boolean(Number(position?.closing_pnl) && Number(position?.closing_pnl) < 0);
    return {
      isMarginTrading: true,
      isLoss,
      inputTitle: position?.account_name,
      inputName: formatAssetAmount({
        amount: Number(position?.amount),
        asset: position?.base_asset || null,
        disableSign: true,
      }),
      inputAsset: position?.symbol_icon || "",
      outputSubTitle: outputCost ? `≈ ${abbreviateCurrency(cost_currency || ECurrency.rub, outputCost)}` : "",
      outputName: formatAssetAmount({
        amount: Number(position?.closing_pnl),
        asset: position?.quote_asset || null,
        sign: isLoss ? SignFormatAssetAmount.MIN : SignFormatAssetAmount.PLS
      }),
      outputAsset: position?.quote_asset || "",
      outputAssetVerified: true
    };
  }
  tradePositionOpening = (item: TTransactionResult): ITransactionInfoPart => {
    const { position } = item;
    const isLoss = Boolean(Number(position?.closing_pnl) && Number(position?.closing_pnl) < 0);

    return {
      isMarginTrading: true,
      isLoss,
      inputTitle: position?.account_name,
      inputName: formatAssetAmount({
        amount: Number(position?.amount),
        asset: position?.base_asset || null,
        disableSign: true,
      }),
      inputAsset: position?.symbol_icon || "",

      outputAsset: position?.quote_asset || "",
      outputAssetVerified: true,
      outputTitle: position?.account_name,
      outputName: `${position?.quote_asset} ${i18n.t("transactionOperationTypeItemMarginTradingOpened.collateral")}`,
      outputSubTitle: "transactionOperationTypeItemMarginTradingOpened.positionOpened"
    };
  }
  // Ручные операции
  manualP2pSale = (item: TTransactionResult): ITransactionInfoPart => {
    const { input, output_ext } = item;
    return {
      inputSubTitle: this.getExchangeRateString(input),
      outputSubTitle: output_ext?.counterparty || OutputTitle.unknown
    };
  }
  manualP2pPurchase = (item: TTransactionResult): ITransactionInfoPart => {
    const { input } = item;
    return {
      inputSubTitle: this.getExchangeRateString(input),
    };
  }

  // Utils
  getExchangeRateString = (input?: TNullable<TTransactionOperation>): string => {
    if(!input?.exchange_symbol || !input?.exchange_rate) {
      return "";
    }

    return `${input.exchange_symbol} = ${formatExchangeRate(Number(input.exchange_rate))}`;
  };
}

export const transactionInfoMapper = new TransactionInfoMapper();



export const handleFileValidate = (files: File[], listFiles?: TSingleTransactionFilesListData[] | null): boolean => {
  const isFileHasLongName = files.some(item => item.name.trim().length > 100);
  return (files.length + (listFiles?.length || 0)) > 3 || isFileHasLongName;
};

export type GroupedTransactions<T> = Dictionary<[...T[]]>
export const getGroupedTransactions = <T extends { datetime: string }>(transactions: T[]): GroupedTransactions<T> => {
  setDateTimeLocale();
  const sort = sortBy(transactions, "datetime");
  return groupBy(sort.reverse(), e => moment(e.datetime).locale(i18n.language).format("DD MMMM YYYY"));
};
