import { FC } from "react";

import { IIconsProps } from "config/types";

{/* eslint-disable max-len */}
const FluentShieldWarning: FC<IIconsProps> = ({ className, onClick }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <g clipPath="url(#clip0_9202_107850)">
      <path d="M13.5 2.19997C11.7247 2.19997 9.99467 1.50845 8.3 0.109999C8.21345 0.0385974 8.10819 0 8 0C7.89182 0 7.78655 0.0385974 7.7 0.109999C6.00533 1.50845 4.27533 2.19997 2.5 2.19997C2.36739 2.19997 2.24021 2.25792 2.14645 2.36106C2.05268 2.46421 2 2.6041 2 2.74997V6.59992C2 10.2673 3.972 12.9622 7.81667 14.6284C7.93448 14.6794 8.06552 14.6794 8.18333 14.6284C12.028 12.9622 14 10.2665 14 6.59992V2.74997C14 2.6041 13.9473 2.46421 13.8536 2.36106C13.7598 2.25792 13.6326 2.19997 13.5 2.19997ZM7.50467 4.12862C7.52113 3.99698 7.58037 3.87635 7.67142 3.78902C7.76248 3.7017 7.8792 3.65359 8 3.65359C8.1208 3.65359 8.23752 3.7017 8.32858 3.78902C8.41963 3.87635 8.47887 3.99698 8.49533 4.12862L8.5 4.20342V8.97003L8.49533 9.04483C8.47887 9.17647 8.41963 9.2971 8.32858 9.38442C8.23752 9.47174 8.1208 9.51985 8 9.51985C7.8792 9.51985 7.76248 9.47174 7.67142 9.38442C7.58037 9.2971 7.52113 9.17647 7.50467 9.04483L7.5 8.97003V4.20342L7.50467 4.12862ZM8 11.7332C7.82319 11.7332 7.65362 11.6559 7.5286 11.5184C7.40357 11.3809 7.33333 11.1944 7.33333 10.9999C7.33333 10.8054 7.40357 10.6189 7.5286 10.4813C7.65362 10.3438 7.82319 10.2665 8 10.2665C8.17681 10.2665 8.34638 10.3438 8.4714 10.4813C8.59643 10.6189 8.66667 10.8054 8.66667 10.9999C8.66667 11.1944 8.59643 11.3809 8.4714 11.5184C8.34638 11.6559 8.17681 11.7332 8 11.7332Z" fill="#FFA39E"/>
    </g>
    <defs>
      <clipPath id="clip0_9202_107850">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default FluentShieldWarning;
