import { FC } from 'react';
import { useDispatch } from "react-redux";

import TelegramIcon from "components/Icons/TelegramIcon";

import { authorizeOAuthRequest } from "store/auth/reducers";
import { TOAuthProvider } from "store/auth/types";

import { showError } from "utils/notifications";

import { analyticEvent } from "../../../../store/analytics/effects";
import { EEventType } from "../../../../store/analytics/types";

import { TIsSignIn } from "../../types";

type TTelegramUser = {
  auth_date: number;
  first_name: string;
  hash: string;
  id: number;
  last_name: string;
  username: string
}

interface Telegram {
  Login: {
    auth: (options: any, callback: any) => void;
  }
}

declare global {
  interface Window {
    Telegram?: Telegram
  }
}

const AuthTelegram: FC<TIsSignIn> = () => {
  const dispatch = useDispatch();

  if (!process.env.REACT_APP_TELEGRAM_BOT_ID) {
    return <></>;
  }

  const onClickTelegramIcon = () => {
    dispatch(analyticEvent(EEventType.AUTH_OAUTH_TELEGRAM_CLICK));
    if (window.Telegram) {
      window.Telegram.Login.auth(
        {
          bot_id: process.env.REACT_APP_TELEGRAM_BOT_ID,
          request_access: "write",
          auth_url: "/auth/oauth/callback/"
        },
        (user: TTelegramUser) => {
          if (!user) {
            showError("Cannot authorize telegram.");
          }
          dispatch(authorizeOAuthRequest({
            auth_data: user,
            provider: TOAuthProvider.telegram
          }));
        }
      );
    }
  };

  return (
    <div className="item sign-icon--tg" onClick={onClickTelegramIcon}>
      <div>
        <TelegramIcon />
      </div>
      <span>Telegram</span>
    </div>
  );
};

export default AuthTelegram;