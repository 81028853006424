import { FC, SVGProps } from "react";

import { paths } from "./paths";

export enum ELinks {
  HOME = "naming.dashboard",
  ASSETS = "naming.exchangesAndWallets",
  DOCUMENTS = "naming.documents",
  REPORTS = "naming.reports",
  PROFILE = "naming.profile",
  SETTINGS = "naming.settings",
  TRANSACTIONS = "naming.operations",
  SETTINGS_MAIN = "naming.main",
  SETTINGS_SAFETY = "naming.securityAndEntry",
  SETTINGS_SERVICES = "naming.connectedServices", // Подключенные услуги
  SETTINGS_REPORTS = "naming.reporting", // Отчетность
  SETTINGS_OTHER = "naming.other",
  SETTINGS_PLAN_AND_PAYMENT = "naming.planAndPayment",
  PRICING = "naming.pricing",
  TRADING_INSIGHT = "naming.tradingInsights",
}


export enum EAssetsTypes { // need remove to assets types
  EXCHANGE = "EXCHANGE",
  BLOCKCHAIN = "BLOCKCHAIN",
  WALLET = "WALLET",
}

export type TNullable<T> = T | null;

export enum EStatus {
  draft = "draft",
  formed = "formed",
  forming = "forming",
  error = "error",
}

export enum ESyncErrorStatus {
  INVALID_API_KEY= "INVALID_API_KEY",
  INVALID_API_SECRET = "INVALID_API_SECRET",
  INVALID_ADDRESS = "INVALID_ADDRESS",
  API_LIMIT_REACHED = "API_LIMIT_REACHED"
}

export enum ESyncStatus {
  synchronized = "synchronized",
  synchronizing = "synchronizing",
  error = "error",
}
export interface IIconsProps {
  className?: string;
  onClick?: () => void;
  fill?: string;
}

export type TFilterData = {
  value: string;
  label: string;
};


export enum ETransactionsOperationsTypes {

  // Внутренние операции
  tradeSpot = "trade:spot", // Сделка на спот
  tradeOtc = "trade:otc", // Обмен OTC
  cryptoTransfer = "crypto:transfer", // Перевод
  innerTransfer = "crypto:inner_transfer", // Внутренний перевод
  blockchainSwap = "blockchain:swap", // Swap на DEX
  blockchainDex = "blockchain:dex", // Операция на DEX (кроме Swap)
  accountTransfer = "account:transfer", // Внутренний перевод

  // Поступление криптовалюты внутри биржи/кошелька
  p2pPurchase = "p2p:purchase", // Покупка на P2P
  fiatIncome = "fiat:income", // Пополнение с карты
  accountAirdrop = "account:airdrop", // Аирдроп
  tradePositionPNL = "trade:position:pnl", // PNL от сделки

  // Поступление криптовалюты с внешних источников
  cryptoIncome = "crypto:income", // Поступление
  cryptoIncomePurchase = "crypto:income:purchase", // Покупка криптовалюты
  cryptoIncomeAirdrop = 'crypto:income:airdrop', // Аирдроп
  cryptoIncomeFork = 'crypto:income:fork', // Форк
  cryptoIncomeMyTransfer = "crypto:income:my_transfer", // Перевод с кошелька
  cryptoIncomeLoan = 'crypto:income:loan', // Получение в долг
  cryptoIncomePayment = "crypto:income:payment",
  cryptoIncomeMining = "crypto:income:mining",
  cryptoIncomeStakingReward = "crypto:income:staking_reward",
  cryptoIncomeStakingReturn = "crypto:income:staking_return",
  cryptoIncomeLendingReward = "crypto:income:lending_reward",
  cryptoIncomeLendingReturn = "crypto:income:lending_return",
  cryptoIncomeP2e = "crypto:income:p2e",
  cryptoIncomeGift = "crypto:income:gift",
  cryptoIncomeOtherReward = "crypto:income:other_reward",

  // Вывод криптовалюты внутри биржи/кошелька
  p2pSale = "p2p:sale", // Продажа на P2P
  fiatOutcome = "fiat:outcome", // Вывод на карту

  // Вывод криптовалюты на внешние аккаунты
  cryptoOutcome = "crypto:outcome", // Вывод
  cryptoOutcomeSale = "crypto:outcome:sale", // Продажа криптовалюты

  // Торговые сделки
  tradePositionOpening = 'trade:position:opening',
  tradePositionClosing = 'trade:position:closing',

  // Ручные операции
  manualCryptoPurchase = "manual:p2p:purchase", // Покупка криптовалюты
  manualCryptoSale = "manual:p2p:sale", // Продажа криптовалюты
}

export enum ETransactionsOperationsGroups {

  groupCryptoSale = "group:crypto_sale", // Продажа криптовалюты
  groupCryptoPurchase = "group:crypto_purchase", // Покупка криптовалюты
}

export enum ETransactionsTypesTranslations {

  all = "transactionTypes.allOperations",

  // new types
  tradeSpot = `transactionTypes.tradeSpot`,
  tradeOtc = "transactionTypes.tradeOtc",
  cryptoTransfer = "transactionTypes.cryptoTransfer",
  innerTransfer = "transactionTypes.accountTransfer",
  cryptoOutcome = "transactionTypes.cryptoOutcome",
  cryptoOutcomeSale = "transactionTypes.cryptoOutcomeSale",
  cryptoIncome = "transactionTypes.cryptoIncome",
  cryptoIncomeAirdrop = "transactionTypes.cryptoIncomeAirdrop",
  cryptoIncomeFork = "transactionTypes.cryptoIncomeFork",
  cryptoIncomeLoan = "transactionTypes.cryptoIncomeLoan",
  cryptoIncomeMyTransfer = "transactionTypes.cryptoIncomeMyTransfer",
  cryptoIncomePurchase = "transactionTypes.cryptoPurchase",
  cryptoIncomePayment = "transactionTypes.cryptoIncomePayment",
  cryptoIncomeMining = "transactionTypes.cryptoIncomeMining",
  cryptoIncomeStakingReward = "transactionTypes.cryptoIncomeStakingReward",
  cryptoIncomeStakingReturn = "transactionTypes.cryptoIncomeStakingReturn",
  cryptoIncomeLendingReward = "transactionTypes.cryptoIncomeLendingReward",
  cryptoIncomeLendingReturn = "transactionTypes.cryptoIncomeLendingReturn",
  cryptoIncomeP2e = "transactionTypes.cryptoIncomeP2e",
  cryptoIncomeGift = "transactionTypes.cryptoIncomeGift",
  cryptoIncomeOtherReward = "transactionTypes.cryptoIncomeOtherReward",
  fiatOutcome = "transactionTypes.fiatOutcome",
  fiatIncome = "transactionTypes.fiatIncome",
  p2pSale = "transactionTypes.p2pSale",
  p2pPurchase = "transactionTypes.p2pPurchase",
  manualP2pSale = "transactionTypes.manualP2pSale",
  manualP2pPurchase = "transactionTypes.manualP2pPurchase",
  cryptoSale = "transactionTypes.cryptoSale",
  cryptoPurchase = "transactionTypes.cryptoPurchase",
  tradePositionOpening = "transactionTypes.opening",
  tradePositionClosing = "transactionTypes.closing",
  blockchainSwap = "transactionTypes.blockchainSwap",
  blockchainDex = "transactionTypes.blockchainDex",
  accountTransfer = "transactionTypes.accountTransfer",
  accountAirdrop = "transactionTypes.accountAirdrop",
  tradePositionPNL = "transactionTypes.tradePositionPNL",
}


export type TRefInput = {
  refinput?: React.MutableRefObject<any>;
};

export type TChildren = React.ReactChild | React.ReactChild[] | React.FC;

export enum EValidateNames {
  email = "email",
  phone = "phone",
}

export type TLinks = {
  id: string;
  to: paths;
  icon?: FC<SVGProps<SVGSVGElement>>;
  title: string;
  active: boolean;
};


export type TPaginationOptions = {
  size?: number;
  page?: number;
}

export interface FormikFieldProps {
  name: string
  validate?: (value: any) => undefined | string | Promise<any>
  fast?: boolean
}

export enum EAppNames  {
  bitnalog= "bitnalog",
  bitOk= "bitOk",
}

export enum ECurrency {
  usd = "USD",
  rub = "RUB",
  usdt = "USDT",
  eur = "EUR",
  gbp = "GBP",
  try = "TRY",
  jpy = "JPY",
  aud = "AUD",
  uah = "UAH",
  thb = "THB",
}

export const CurrenciesSymbols = {
  [ECurrency.rub]: "₽",
  [ECurrency.usd]: "$",
  [ECurrency.usdt]: "$",
  [ECurrency.eur]: "€",
  [ECurrency.gbp]: "£",
  [ECurrency.try]: "₺",
  [ECurrency.jpy]: "¥",
  [ECurrency.aud]: "A$",
  [ECurrency.uah]: "₴",
  [ECurrency.thb]: "฿",
};
