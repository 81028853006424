import React, { FC, memo } from 'react';
import cn from "classnames";

import FluentShieldOk from 'components/Icons/FluentShieldOk';
import FluentShieldWarning from 'components/Icons/FluentShieldWarning';
import FluentShieldError from 'components/Icons/FluentShieldError';

import { getRiskStatus } from "../../utils/risks";

import { ERiskStatus } from "./types";

export type TFormedRiskIconProps = {
  risk: number;
  className?: string;
};

export const RiskStatusIcons = {
  [ERiskStatus.OK]: FluentShieldOk,
  [ERiskStatus.WARNING]: FluentShieldWarning,
  [ERiskStatus.ERROR]: FluentShieldError,
};

const FormedRiskIcon: FC<TFormedRiskIconProps> = memo(({ risk, className }) => {
  const status = getRiskStatus(risk);
  return React.createElement(RiskStatusIcons[status], {
    className: cn("formed-risk-icon", className)
  });
});

export default FormedRiskIcon;
