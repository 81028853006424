import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Drawer } from "antd";
import cn from "classnames";

import { maxMobileMediaWidth } from "config/constants";

import { dashboardOnboardingSelector } from "store/common/selectors";
import { setHeaderDrawer } from "store/common/reducers";

import DashboardMobileOnboardingCarousel from "../DashboardMobileOnboardingCarousel";

const DashboardMobileOnboarding: FC = () => {
  const dispatch = useDispatch();
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const { isRun } = useSelector(dashboardOnboardingSelector);

  if (!isRun || !isMaxMobile || process.env.REACT_APP_ONBOARDING !== 'true') return null;

  const onClose = () => {
    dispatch(setHeaderDrawer(false));
  };

  return (
    <Drawer
      width={"100%"}
      placement="left"
      closable={false}
      onClose={onClose}
      visible={isRun}
      className={cn("dashboard-mobile-onboarding")}
    >
      <DashboardMobileOnboardingCarousel/>
    </Drawer>
  );
};

export default DashboardMobileOnboarding;
