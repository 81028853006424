import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { maxMobileMediaWidth } from "config/constants";

import SearchInput from "components/Inputs/SearchInput";
import Button from "components/Buttons/Button";
import PlusAccountMobile from "components/Icons/PlusAccountMobile";

import { openModal } from "store/modals/reducers";
import { EModals } from "store/modals/types";

import AssetsList from "../AseetsList";

const AssetsSidebar = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const isMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });

  const showModal = () => {
    dispatch(openModal(EModals.assetModal));
  };

  return (
    <aside className="assets-sidebar">
      <div className="assets-form">
        <Button
          title={!isMobile ? t('action.add') : ""}
          onClick={showModal}
          className="assets-btn"
          icon={<PlusAccountMobile/>}
          pink={true}
        />
        <SearchInput setSearch={setSearch} />
      </div>
      <AssetsList searchValue={search} />
    </aside>
  );
};

export default AssetsSidebar;
