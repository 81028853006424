import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

import { TAmlOutputSelector, TAmlSingleChecksState, TAmlSingleStateState } from "./types";

const selectState = (state: IApplicationState) => state.aml;

export const amlSingleStateSelector = (id: string | undefined): TAmlOutputSelector<TAmlSingleStateState|undefined> =>
  createSelector(selectState, aml =>
    id ? aml?.amlSingleStateState[id] && aml?.amlSingleStateState[id] : undefined
  );

export const amlSingleCheckSelector = createSelector(
  selectState, aml => aml.amlSingleCheckState);

export const amlSingleChecksSelector = (id: string | undefined): TAmlOutputSelector<TAmlSingleChecksState|undefined> =>
  createSelector(
    selectState, aml => {
      if (!id) return undefined;
      return aml?.amlSingleChecksState[id];
    }
  );

export const amlStateSelector = createSelector(
  selectState, aml => aml.amlStateState);
export const amlCheckManuallyStateSelector = createSelector(
  selectState, aml => aml.amlCheckManuallyState);
export const amlRiskSummaryStateSelector = createSelector(
  selectState, aml => aml.amlRiskSummaryState);

export const amlActivateDropdownSelector = createSelector(
  selectState, aml => aml.amlActivateDropdown);
export const amlPublicSingleStateSelector = createSelector(
  selectState, aml => aml.amlPublicSingleState);
