import { FC, SVGProps } from "react";

{/* eslint-disable max-len */}
const PlusAccountMobile: FC<SVGProps<SVGSVGElement>> = ({ className, onClick }):JSX.Element => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <path d="M15.7656 7.42188V9.1875H0.171875V7.42188H15.7656ZM8.84375 0.15625V16.7188H6.96875V0.15625H8.84375Z" fill="white"/>
  </svg>
);

export default PlusAccountMobile;

