import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { getAssetSingleId, getAssetsSinglePath } from "config/paths";
import { ESyncStatus } from "config/types";

import FormedRiskIcon from "components/FormedRiskIcon";
import SyncBadge from "components/SyncBadge";

import { getAssetsDataSelector } from "store/assets/selectors";
import { TMyAssetsData } from "store/assets/types";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import { formAssetsName } from "utils/assetsHelper";
import { prepareRiskValue } from "utils/risks";

const AssetsListItem: React.FC<TMyAssetsData> = asset => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const id = getAssetSingleId();
  const assets = useSelector(getAssetsDataSelector);

  const getAssetType = (myAsset?: TMyAssetsData) => assets?.find($asset => $asset.id === myAsset?.stock)?.type;
  const showBadge = [
    ESyncStatus.synchronizing,
    ESyncStatus.error,
  ].includes(asset.status);

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (asset.id !== id) {
      history.push(getAssetsSinglePath(asset.id));
      dispatch(analyticEvent(EEventType.WALLET_SHOW_ACCOUNT_INFO));
    }
  };

  return (
    <a
      className={cn("assets-item", { active: asset.id === id })}
      key={asset.id}
      onClick={onClick}
    >
      <span className="assets-item-logo__wrapper">
        <img src={asset.icon} alt="assets-icon" className="assets-item-logo" />
        {showBadge &&
          <SyncBadge
            isSpin={asset.status === ESyncStatus.synchronizing}
            error={asset.status === ESyncStatus.error}
            className="assets-item-logo__sync-badge"
          />}
      </span>
      <h3 className={cn("assets-item-title", { riskScore: !!asset?.risk_score })}>
        {formAssetsName(asset.name, getAssetType(asset))}
      </h3>
      {(asset?.risk_score !== null && asset?.risk_score !== undefined)  && <div className="assets-item-risk">
        <FormedRiskIcon risk={asset?.risk_score} className="assets-item-risk__icon"/>
        {t("amlPage.risk")}: {prepareRiskValue(asset?.risk_score)}%
      </div>}
    </a>
  );
};

export default AssetsListItem;
