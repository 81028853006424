import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { getAMLSingleId, getAMLSinglePath, paths } from "config/paths";
import { QUERIES } from "config/constants";

import Button from "components/Buttons/Button";
import EqualizerIcon from 'components/Icons/EqualizerIcon';
import ClarityArchiveLineIcon from 'components/Icons/ClarityArchiveLineIcon';

import useQuery from "hooks/useQuery";

import { EModals } from "store/modals/types";
import { openModal } from "store/modals/reducers";
import { getMyAssetsRequest } from "store/assets/reducers";
import { getAMLAssetsSelector } from "store/assets/selectors";
import { amlStateRequest } from 'store/aml/reducers';
import { amlStateSelector } from 'store/aml/selectors';
import { EAmlSingleIds } from "store/aml/types";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import AMLPageWrapperWallets from '../AMLPageWrapperWallets';
import AMLPageWrapperChecks from "../AMLPageWrapperChecks";

const AMLPageWrapperSidebar: FC = () =>  {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const amlSingleId = getAMLSingleId();
  const isManual = amlSingleId === EAmlSingleIds.manual;
  const isArchived = amlSingleId === EAmlSingleIds.archived;

  const assets = useSelector(getAMLAssetsSelector);
  const { data: amlState } = useSelector(amlStateSelector);

  const onClickAddWallet = () => {
    dispatch(analyticEvent(EEventType.AML_ADD_WALLET));
    dispatch(openModal(EModals.amlAddWallet));

    dispatch(openModal(EModals.assetModal));
    query.append(QUERIES.isAml, String(true));
    history.replace({ search: query.toString() });
  };

  const onClickManualChecks = () => {
    if (isManual) {
      history.push(
        paths.AML
      );
    } else {
      history.push(
        getAMLSinglePath(EAmlSingleIds.manual)
      );
    }
  };

  const onClickArchive = () => {
    if (isArchived) {
      history.push(
        paths.AML
      );
    } else {
      history.push(
        getAMLSinglePath(EAmlSingleIds.archived)
      );
    }
  };

  useEffect(() => {
    dispatch(getMyAssetsRequest({ params: { aml_support: true } }));
    dispatch(amlStateRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <aside className="aml-sidebar container mob-p-16">
      <AMLPageWrapperChecks/>
      <AMLPageWrapperWallets onClickAddWallet={onClickAddWallet} assets={assets}/>
      {!!assets?.length && <Button
        pink={true}
        title={t("amlPage.addWallet")}
        onClick={onClickAddWallet}
        className="aml-btn"
      />}
      <div className="aml-sidebar__additional">
        <div
          className={cn("aml-sidebar__manual-checks", { active: isManual })}
          onClick={onClickManualChecks}
        >
          <EqualizerIcon/>
          <span className="title">{t("amlPage.manualChecks")}</span>
          <span className="count">{amlState?.manual_checks || 0}</span>
        </div>
        {!!amlState?.archived_checks && <div
          className={cn("aml-sidebar__archive", { active: isArchived })}
          onClick={onClickArchive}
        >
          <ClarityArchiveLineIcon/>
          <span className="title">{t("amlPage.archived")}</span>
          <span className="count">{amlState?.archived_checks}</span>
        </div>}
      </div>
    </aside>
  );
};

export default AMLPageWrapperSidebar;
