import { FC, useEffect, useRef } from "react";
import { Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { QUERIES } from "config/constants";

import useQuery from "hooks/useQuery";

import { 
  serviceDetailsSelector, 
  servicesAllSelectByCodeSelector,
} from "store/services/selectors";
import { serviceDetailsRequest } from "store/services/reducers";
import { EServiceCode } from "store/services/types";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import PriceActivateMainInfo from "../PriceActivateMainInfo";
import PriceActivateMainInfoOverlay from "../PriceActivateMainInfoOverlay";
import { TPricingActivatePlanProps } from "../PricingActivatePlan";

export type TPriceActivateMainInfoDropdownProps = Pick<
  TPricingActivatePlanProps, "currency" | "activePricingDropdown" | "setActivePricingDropdown"> & { disabled?: boolean }

const PriceActivateMainInfoDropdown: FC<TPriceActivateMainInfoDropdownProps> = ({
  currency, activePricingDropdown, setActivePricingDropdown, disabled
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const queryPlan = query.get(QUERIES.code) as EServiceCode;
  const wrapRef = useRef<HTMLDivElement>(null);
  const service = useSelector(servicesAllSelectByCodeSelector(queryPlan));
  const { data: serviceDetail, fetching } = useSelector(serviceDetailsSelector);
  
  useEffect(() => {
    if (service?.id) {
      dispatch(serviceDetailsRequest({ id: service.id, currency }));
    }
  }, [dispatch, service?.id, currency]);

  useEffect(() => {
    if (serviceDetail?.code) query.set(QUERIES.code, serviceDetail?.code);
    history.replace({
      search: query.toString()
    });
  }, [serviceDetail]);

  useEffect(() => {
    if (activePricingDropdown) {
      dispatch(analyticEvent(EEventType.PAYMENT_STEP1_ALT_CLICKED));
    }
  }, [activePricingDropdown]);

  return (
    <Dropdown
      overlay={<PriceActivateMainInfoOverlay
        wrapRef={wrapRef}
        setVisible={setActivePricingDropdown}
        variants={serviceDetail?.variants}
      />}
      trigger={["click"]}
      visible={activePricingDropdown}
      disabled={fetching}
      align={{ offset: [0, -2] }}
    >
      <>
        {serviceDetail && <PriceActivateMainInfo
          wrapRef={wrapRef}
          service={serviceDetail}
          showVector={!!serviceDetail?.variants.length && !disabled}
          vectorActive={activePricingDropdown}
          setVectorToggle={!disabled ? () => setActivePricingDropdown($visible => !$visible) : undefined}
        />}
      </>
    </Dropdown>
  );
};

export default PriceActivateMainInfoDropdown;
