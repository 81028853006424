import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ECurrency } from "config/types";
import { QUERIES } from "config/constants";

import Button from "components/Buttons/Button";

import useQuery from "hooks/useQuery";

import { userPaymentMethodsSelector, userPlanInfoSelector } from "store/user/selectors";
import { userPaymentMethodsRequest } from "store/user/reducers";
import { EServiceCode } from "store/services/types";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import { EPricingActivateModalSteps } from "../../index";

import PriceActivatePromoCodeForm from "../PriceActivatePromoCodeForm";
import PriceActivateMainInfoDropdown from "../PriceActivateMainInfoDropdown";
import PriceActivatePaymentCurrency from "../PriceActivatePaymentCurrency";

export type TPricingActivatePlanProps = {
  setStep: (val: EPricingActivateModalSteps) => void;
  currency: ECurrency;
  setCurrency: (val: ECurrency) => void
  activePricingDropdown: boolean
  setActivePricingDropdown: (val: boolean | ((prevState: boolean) => boolean)) => void
}

const PricingActivatePlan: FC<TPricingActivatePlanProps> = ({
  setStep, currency, setCurrency , activePricingDropdown, setActivePricingDropdown
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fetching: userPaymentLoading } = useSelector(userPaymentMethodsSelector);
  const { data: userPlan } = useSelector(userPlanInfoSelector);
  const query = useQuery();
  const code = query.get(QUERIES.code) as EServiceCode;

  const disabledDropdown = () => (userPlan?.plans_to_prepaid
      && userPlan.plans_to_prepaid.includes(code));

  const onClick = () => {
    dispatch(analyticEvent(EEventType.PAYMENT_STEP1_NEXT));
    setStep(EPricingActivateModalSteps.two);
  };

  useEffect(() => {
    dispatch(userPaymentMethodsRequest({ currency }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  return (
    <>
      <PriceActivatePaymentCurrency currency={currency} setCurrency={setCurrency} />
      <PriceActivateMainInfoDropdown
        currency={currency}
        activePricingDropdown={activePricingDropdown}
        setActivePricingDropdown={setActivePricingDropdown}
        disabled={disabledDropdown()}
      />
      <PriceActivatePromoCodeForm currency={currency} />
      <div className="pricing__activate__btn__wrapper">
        <Button
          title={t("serviceModal.toPay")}
          className="pricing__activate__btn"
          disabled={userPaymentLoading}
          onClick={onClick}
        />
      </div>
    </>
  );
};

export default PricingActivatePlan;
