import { FC, useEffect, useState } from "react";
import { Menu } from "antd";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { paths } from "config/paths";

import ExitIcon from "components/Icons/ExitIcon";
import { SETTINGS_LINKS } from "components/SettingsPageWrapper/components/SettingsSidebar/constants";
import SettingIcon from "components/Icons/SettingIcon";
import { useLayoutAppNameContext } from "components/Layout/hooks";

import { getUserInfoDataSelector } from "store/user/selectors";

const { SubMenu: AntSubmenu } = Menu;
const SUM_MENU = "settings-submenu";

const SubMenu: FC<{ onClose: () => void }> = ({ onClose }) => {
  const location = useLocation();
  const { logout } = useLayoutAppNameContext();
  const [openKeys, setOpenKeys] =
    useState<string[]>(() => location.pathname.includes(paths.SETTINGS) ? [SUM_MENU] : [""]);
  const { t } = useTranslation();
  const data = useSelector(getUserInfoDataSelector);

  useEffect(() => {
    setOpenKeys(() => location.pathname.includes(paths.SETTINGS) ? [SUM_MENU] : [""]);
  }, [location.pathname]);

  return (
    <div className="nav-item nav-item--submenu">
      <Menu
        mode="inline"
        className="submenu-nav-item"
        openKeys={openKeys}
      >
        <AntSubmenu
          icon={
            <div className="link-logo settings-icon">
              <SettingIcon />
            </div>
          }
          title={t('naming.settings')}
          key={SUM_MENU}
          onTitleClick={() => {
            setOpenKeys(prev => prev.includes(SUM_MENU) ? [""] : [SUM_MENU]);
          }}
        >
          {SETTINGS_LINKS.filter(link => link.active).map(link => (
            <NavLink
              key={link.id}
              to={link.to}
              exact={true}
              className="nav-item_link"
              activeClassName="nav-item_link-active"
              onClick={onClose}
            >
              <span className="link-text">{t(link.title)}</span>
            </NavLink>
          ))}
        </AntSubmenu>
        <li className="nav-item nav-item--exit" onClick={logout}>
          <div className="nav-item_link">
            <div className="link-logo">
              <ExitIcon />
            </div>
            <span className="link-text">{t("naming.logout")}</span>
          </div>
        </li>
        <div className="nav-username">{data?.username || data?.email || data?.phone}</div>
      </Menu>
    </div>
  );
};

export default SubMenu;
