import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { FC, useMemo } from "react";

import { ECurrency, TNullable } from "config/types";
import { paths } from "config/paths";

import { EStorageKeys } from "utils/storageHeplers";

import { ELanguages } from "../../../../../i18n/constants";
import MainSettingsCurrency from "../../../../MainSettings/components/MainSettingsCurrency";
import MainSettingsLanguageContent from "../../../../MainSettings/components/MainSettingsLanguageContent";
import FormObserver from "../../../../FormObserver";
import { useLayoutAppNameContext } from "../../../../Layout/hooks";

type PublicMobileSettingsValues = {
  [EStorageKeys.I18NEXT_LNG]: TNullable<ELanguages>;
  [EStorageKeys.CURRENCY]: TNullable<ECurrency>;
}

const PublicMobileSettingsForm: FC = () => {
  const { t } = useTranslation();
  const { routePath } = useLayoutAppNameContext();
  const language = localStorage.getItem(EStorageKeys.I18NEXT_LNG) as ELanguages|undefined;
  const currency = localStorage.getItem(EStorageKeys.CURRENCY) as ECurrency|undefined;

  const initialValues: PublicMobileSettingsValues = useMemo(() => ({
    [EStorageKeys.I18NEXT_LNG]: language ? language : ELanguages.enUS,
    [EStorageKeys.CURRENCY]: currency ? currency : ECurrency.usd,
  }), [language, currency]);

  const onSubmit = (values: PublicMobileSettingsValues) => {
    Object.entries(values).forEach(([key, value]) => {
      if (!value) return;
      localStorage.setItem(key, value);
    });
  };

  return (
    <div className="container">
      <Formik<PublicMobileSettingsValues>
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <Form className="settings-main settings isPublic">
          <FormObserver onChangeValues={onSubmit}/>
          <div className="settings__item language-item">
            <h3 className="item-title">{t("mainSettings.language")}</h3>
            <MainSettingsLanguageContent name={EStorageKeys.I18NEXT_LNG} />
          </div>
          {routePath !== paths.PUBLIC_AML && <div className="settings__item">
            <h3 className="item-title">{t("mainSettings.currency")}</h3>
            <MainSettingsCurrency/>
          </div>}
        </Form>
      </Formik>
    </div>
  );
};

export default PublicMobileSettingsForm;