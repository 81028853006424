import { FC } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import cn from "classnames";

import { maxTableMediaWidth, minShowSidebarWidth } from "config/constants";

import HeaderCurrency from "./components/HeaderCurrency";
import HeaderChecksLeft from "./components/HeaderChecksLeft";
import HeaderSyncAccounts from "./components/HeaderSyncAccounts";
import HeaderAdditional from "./components/HeaderAdditional";
import NewHeaderDrawer from "./components/NewHeaderDrawer";

const NewHeader: FC = () => {
  const notTable = useMediaQuery({ minWidth: maxTableMediaWidth });

  return (
    <div className="new-header__wrapper">
      <MediaQuery maxWidth={minShowSidebarWidth - 1}>
        <NewHeaderDrawer/>
      </MediaQuery>

      <div className={cn("new-header", { container: notTable })}>
        <div className="new-header__left">
          <HeaderChecksLeft/>
        </div>
        <div className="new-header__right">
          <HeaderCurrency/>
          <HeaderSyncAccounts/>
          <HeaderAdditional/>
        </div>
      </div>
    </div>
  );
};

export default NewHeader;
