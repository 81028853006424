import { OutputSelector } from "reselect";

import { IApplicationState } from "../rootInterface";

export enum EModals {
  activateServiceModal = "activateService",
  assetModal = "assetModal",
  dashboardSymbolsSetSequence = "dashboardSymbolsSetSequence",
  documentCreateModal = "documentCreateModal",
  documentSetCountry = "documentSetCountry",
  incomingOperation = "incomingOperation",
  transactionEditNote = "transactionEditNote",
  transactionEditTag = "transactionEditTag",
  activatePricing = "activatePricing",
  pricingTariffUpgrade = "pricingTariffUpgrade", // Апгрейд тарифа
  pricingSelectTariffForNextRenewal = "pricingSelectTariffForNextRenewal", // Выбор тарифа для следующего продления
  pricingTariffPrepayment = "pricingTariffPrepayment", // Предоплата тарифа
  pricingReplacingTariffWithReplaceCurrent =
    "pricingReplacingTariffWithReplaceCurrent", // Замена тарифа с фризом текущего
  planAndPaymentUnsubscribe = "planAndPaymentUnsubscribe",
  planAndPaymentPreOrderDelete = "planAndPaymentPreOrderDelete",
  planAndPaymentPrePurchaseHistory = "planAndPaymentPrePurchaseHistory",
  planAndPaymentDeletePaymentMethod = "planAndPaymentDeletePaymentMethod",
  amlAddWallet = "amlAddWallet",
  amlCheckConfirm = "amlCheckConfirm",
  amlCheckOver = "amlCheckOver",
  upgradePlan = "upgradePlan",
  settingsNewPaymentMethod = "settingsNewPaymentMethod",
  amlActivate = "amlActivate",
  shareLink = "shareLink",
  deleteReport = "deleteReport",
  continueCreateReport = "continueCreateReport",
  cancelCreateReport = "cancelCreateReport",
  publicWalletDisable = "publicWalletDisable"
}

export type ToggleModalActionPayload = {
  modal: EModals,
  visible: boolean
}

export type TModalsStoreState = {[key in EModals]?: boolean};


export type TModalsOutputSelector<T> = OutputSelector<IApplicationState, T, (s: TModalsStoreState) => T>;
